const en = {
  appName: "Neighbourhood",
  supportEmail: "partners@residently.com",
  residentlyUrl: "https://residently.com",
  currency: "£",
  add: "Add",
  cancel: "Cancel",
  submit: "Submit",
  confirm: "Confirm",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  archive: "Archive",
  close: "Close",
  preview: "Preview",
  approve: "Approve",
  decline: "Decline",
  reject: "Reject",
  publish: "Publish",
  unpublish: "Unpublish",
  download: "Download",
  manage: "Manage",
  manageResource: "Manage %{resource}",
  retry: "Retry",
  view: "View",
  copy: "Copy",
  yes: "Yes",
  no: "No",
  none: "None",
  unknown: "Unknown",
  perMonth: "pm",
  perYear: "/year",
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  errors: {
    notFound: "Sorry, that page can't be found.",
    other: {
      message:
        "Sorry, something went wrong. If the problem persists, please contact ",
      email: "partners@residently.com",
    },
    dashboardLink: "Go to dashboard",
    propertiesLink: "Go to properties",
    select: "Please select an option",
    unsavedChanges: "You have unsaved changes, please save them first",
    genericRequestError:
      "Sorry, we couldn't complete that action. Please try again or let us know if the problem persists",
  },
  returnTo: {
    prefix: "Return to %{resource}",
    resource: {
      lets: "property lets",
    },
  },
  success: {
    created: "%{name} added successfully",
    updated: "Your changes have been saved",
    deleted: "The %{name} has been deleted",
    closed: "The %{name} has been closed",
    archived: "%{name} archived successfully",
    cancelled: "%{name} cancelled successfully",
    accepted: "%{name} accepted successfully",
    declined: "%{name} declined successfully",
    rejected: "%{name} rejected successfully",
    invited: "Invite sent successfully",
    published: "%{name} published",
    unpublished: "%{name} unpublished",
    noShow: "%{name} marked as no show",
  },
  notices: {
    unsaved: "You have unsaved changes",
  },
  charges: {
    helperText: {
      securityDeposit: "Can be calculated or entered manually",
      holdingDeposit: "Can be calculated or entered manually",
      upfrontRentAmount: "Can be calculated or entered manually",
    },
  },
  notes: {
    input: "Type your note here...",
    addButton: "Add note",
    empty: "No notes yet",
    errors: {
      empty: "Note can't be blank",
    },
  },
  stays: {
    pageTitleError: "Error loading details",
    newOffersPageTitle: "New offers - Applications",
    approvedOffersPageTitle: "Approved offers - Applications",
    recentlyCompletedPageTitle: "Recently completed - Applications",
    declinedPageTitle: "Declined - Applications",
    pageTitle: "Stays",
    title: "Stays",
    noStays: "Would you like to add a stay to this property?",
    startNewStay: "Add a stay",
    applications: "Applications",
    addApplication: "Add application",
    offersApproved: "Offers Approved",
    offersUnapproved: "Offers Unapproved",
    rollingEndDate: "Rolling",
    error: "There was an error loading your stays: %{error}",
    applicationCount: {
      one: "%{count} application found",
      other: "%{count} applications found",
    },
    activeStaysCount: {
      one: "%{count} active stay found",
      other: "%{count} active stays found",
    },
    label: {
      stays: "Stays",
      dates: "Dates",
      address: "Address",
      residents: "Residents",
      residentDetails: "Resident details",
      stayDetails: "Stay details",
      stayCharges: "Charges",
    },
    addStay: "Add application details",
    editStay: "Edit stay details",
    newStayAdded: "New stay has been successfully created",
    stayTypeForm: {
      placeholder: {
        stayType: "What type of stay would you like to add?",
      },
      options: {
        new: {
          label: "Add new stay",
          description:
            "This will start the offer and application process with new residents",
        },
        insitu: {
          label: "Add in situ stay",
          description:
            "Add stay details for residents already living in the property",
        },
      },
    },
    prefillContactAddress: "Use property address",
    residentDetailsForm: {
      title: "Lead resident details",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      phone: "Phone number",
      start: "When did the resident join this stay?",
      residentsCount: "Number of residents",
      childrenCount: "Children (under 18)",
      placeholder: {
        date: "DD/MM/YYYY",
      },
      dateOptions: {
        start: "From the start of the stay",
        custom: "From another date",
      },
      errors: {
        firstName: "First name is required",
        lastName: "Last name is required",
        email: {
          missing: "Email address is required",
          invalid: "Please enter a valid email address",
        },
        phone: "Phone number is required",
        residentsCount: "Number of residents can't be negative",
        childrenCount: "Children (under 18) can't be negative",
        start: "Join date is required",
      },
    },
    guarantorDetailsForm: {
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      phone: "Phone number",
      start: "When did the guarantor join this stay?",
      guarantorFor: {
        label: "Who is this guarantor for?",
        placeholder: "Please select a resident",
        everyone: "Everyone",
      },
      placeholder: {
        date: "DD/MM/YYYY",
      },
      dateOptions: {
        start: "From the start of the stay",
        custom: "From another date",
      },
      errors: {
        firstName: "First name is required",
        lastName: "Last name is required",
        email: {
          missing: "Email address is required",
          invalid: "Please enter a valid email address",
        },
        phone: "Phone number is required",
        start: "Join date is required",
        guarantorFor: "Resident is required",
      },
    },
    occupierDetailsForm: {
      title: "Add an occupier",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      occupierType: "How old are they?",
      under18: "Under 18",
      over18: "18 or over",
      errors: {
        firstName: "First name is required",
        lastName: "Last name is required",
        occupierType: "Occupier type must be selected",
      },
    },
    stayDetailsForm: {
      addCondition: "+ Add another condition",
      sections: {
        dates: "Dates",
        noticePeriods: "Notice periods",
        offerConditions: "Offer conditions",
      },
      label: {
        startDate: "Start date",
        endDate: "End date",
        leaseLength: "Choose lease length",
        furnished: "Is the property furnished?",
        offerConditions: "Additional conditions",
        residentsBreakDate: "Residents break date",
        residentsNoticeInMonths: "Resident notice in months",
        landlordBreakDate: "Landlord break date",
        landlordNoticeInMonths: "Landlord notice in months",
      },
      placeholder: {
        offerConditions: "Condition (optional)",
      },
      furnishedStatus: {
        furnished: "Furnished",
        unfurnished: "Unfurnished",
      },
      errors: {
        startDate: {
          missing: "Please provide a move in date",
          bankHoliday: "Sorry, move in date can't be a bank holiday",
          minDate: "Move in must be after %{minStartDate}",
        },
        landlordBreakDate: {
          afterEnd: "Must be before the end date",
        },
        residentsBreakDate: {
          afterEnd: "Must be before the end date",
        },
        workingDaysMessage: "Please allow 5 working days before the start date",
        calculateButton: {
          holdingDeposit: "holding deposit",
          securityDeposit: "security deposit",
          upfrontRentAmount: "upfront rent amount",
          valuesRequired: {
            one: "%{missingValues} is required to set a calculated %{chargeType}",
            other:
              "%{missingValues} are required to set a calculated %{chargeType}",
          },
          calculateRequestError:
            "Sorry, we couldn't calculate the %{chargeType}. Please try again in a moment, or enter one manually",
        },
        minimumAmount: "Minimum value of £1",
      },
      tooltip: {
        calculateButton: "Calculate using your portfolio settings",
      },
    },
    chargesForm: {
      sections: {
        monthly: "Monthly charges",
        upfront: "Upfront charges",
        deposits: "Deposits",
      },
      label: {
        upfrontRentMonths: "Upfront rent in months",
        upfrontRent: "Upfront rent amount",
        offerAmount: "Total monthly rent amount",
        holdingDeposit: "Holding deposit",
        securityDeposit: "Security deposit",
        paymentDayOfMonth: "Payment day",
      },
      tooltip: {
        upfrontRent:
          "How much a resident needs to pay before moving in, in addition to their security deposit",
      },
      placeholder: {
        upfrontRent: "Upfront rent",
        offerAmount: "Rent amount",
        holdingDeposit: "Holding deposit amount",
        securityDeposit: "Security deposit amount",
        holdingDepositPaymentMethod:
          "Do you want Residently to collect the holding deposit?",
      },
      helperText: {
        upfrontRent: "Total upfront rent to pay, including any pro-rata",
      },
      errors: {
        offerAmount: {
          invalid: "Rent amount must be at least £1",
        },
        holdingDeposit: {
          invalid: "Holding deposit must be populated, even if it's £0",
        },
        securityDeposit: {
          invalid: "Security deposit must be populated, even if it's £0",
        },
        holdingDepositPaymentMethod: {
          invalid: "Please select an option",
        },
        upfrontRentAmount: {
          invalid: "Upfront rent amount must be at least £1",
        },
        charges: {
          tooLow: "Must be at least £1",
        },
      },
      submitDescription:
        "Clicking finish will invite the resident to complete and submit their offer",
      radioDisabledReason:
        "Holding deposit will not be collected as the amount is zero",
    },
    editStayForm: {
      label: {
        finalEndDate: "End date",
        paymentReference: "Payment reference",
        stayReferenceId: "Your tenancy reference",
      },
      tooltip: {
        finalEndDate:
          "This is the confirmed end date, when the residents have handed in notice and is due to leave",
        paymentReference:
          "This reference will be given to residents to quote when making their upfront payment",
        stayReferenceId: "This is your internal tenancy reference",
      },
      placeholder: {
        finalEndDate: "DD/MM/YYYY",
        paymentReference: "Payment reference",
        stayReferenceId: "Reference number",
      },
      errors: {},
    },
    staysSummary: {
      tabs: {
        people: "People",
        references: "References",
        terms: "Terms",
        landlord: "Landlords & Signatories",
        payments: "Payments",
        contracts: "Contracts",
        notes: "Notes",
      },
      tabError:
        "Sorry, something went wrong loading %{tab}. If the problem persists, please contact ",
      startDate: "Start date",
      endDate: "End date",
      currentTermLength: "Current term length",
      lease: "%{leaseLength} months",
      leaseAndBreak: "%{leaseLength} months, %{breakPeriod} month break",
      rolling: "Rolling",
      currentRent: "Current rent",
      otherCharges: "Other monthly charges",
      ourStayId: "Residently stay ID",
      paymentRef: "Payment reference",
      theirStayId: "Your tenancy reference",
      people: {
        residents: "Residents",
        guarantors: "Guarantors",
        legalOccupiers: "Legal Occupiers",
        archived: "Archived",
        detailTitles: {
          phone: "Phone number",
          email: "Email address",
          startDate: "Start date",
          endDate: "End date",
          nationality: "Nationality",
          dateOfBirth: "Date of birth",
          archivedDate: "Archived date",
        },
        user_type: {
          lead_resident: "Resident",
          resident: "Resident",
          guarantor: "Guarantor",
          occupier: "Occupier",
        },
        buttonTitle: {
          addAPerson: "Add a person",
          addOccupier: "Legal occupier",
          addResident: "Resident",
          addGuarantor: "Guarantor",
        },
      },
      landlord: {
        signatoryName: "Signatory name",
        signatoryEmail: "Signatory email",
        deleteModal: {
          title: "Delete Landlord",
          description:
            "Are you sure you want to delete the landlord %{landlord}?",
          error:
            "Sorry, we couldn't complete that action. Please try again or let us know if the problem persists",
        },
      },
      archiveModal: {
        title: {
          resi: "Archive resident",
          leadResi: "Archive lead resident",
          guarantor: "Archive guarantor",
          occupier: "Archive occupier",
        },
        nominateLeadResident: {
          title: "Nominate lead resident",
          description:
            "You need to nominate one of the other residents as a lead resident first.",
          label: "Please select a resident",
        },
        endDate: {
          title: "Enter the end date",
        },
        invalidResidents: {
          title: "Unable to archive resident",
          description:
            "A stay needs at least one resident. You must add a new resident first, before you can archive this one.",
        },
        errors: {
          endDate: {
            empty: "Please select an end date.",
          },
          nominatedLeadResident: {
            empty: "Please select a new lead resident.",
          },
          archivingFailed: "Something went wrong, please try again",
        },
      },
    },
    contracts: {
      contractSetup: "Contract setup",
      editFormTitle: "Edit contract",
      contractType: "Contract type",
      issuedOn: "Issued on",
      unissuedContract: "Unissued contract for %{address}",
      publishedOn: "Published on",
      resident: "Resident",
      lead_resident: "Resident",
      lead: "Resident",
      guarantor: "Guarantor",
      viewContract: "View contract",
      createContract: "Create a new contract",
      template: "Template",
      noContracts: "No contracts yet",
      status: {
        undefined: "-",
        not_ready: "Not Ready",
        ready: "Ready",
        error: "Error",
        errored: "Error",
        generating: "Generating",
        generated: "Generated",
        issued: "Issued",
        issuing: "Issuing",
        signed: "Signed",
        uploaded: "Uploaded",
      },
      complianceDocuments: {
        rentingGuides: {
          how_to_rent: "How to rent guide - England",
          prt: "PRT easy read notes - Scotland",
        },
        dps: "Deposit protection scheme",
        epc: "EPC",
        eicr: "EICR",
        gas: "Gas safety certificate",
      },
      contractCrud: {
        selectTerm: "Select a term",
        selectTemplate: "Select a template",
        yes: "Yes",
        no: "No",
        selectDPS: "Select a deposit protection scheme",
        optional: "Optional",
        additionalClause: "Add additional clause",
        additionalClauseLabel: "Start typing your additional clause",
        uploadAdditionalDocument: "Upload additional documents",
        additionalDocumentsSubTitle:
          "Additional documents will be inserted at the end of the contract sent for signing.",
        uploadFilesLimit: "Only .pdf, .jpg, .jpeg, .png files",
        insertDocuments: "Insert these documents within the contract",
        newUpload: {
          retry: "RETRY",
        },
        optionalSections:
          "This template has some options for which sections to include:",
        errors: {
          term: "Term is required",
          template: "Template is required",
          dps: "Deposit protection scheme is required",
          upload: "Upload failed, please try again",
          missingContractOptionValue:
            "Please provide a value for this contract section",
        },
      },
      view: {
        listTitle: "Contract details",
        term: "Term",
        contractType: "Contract type",
        generatedOn: "Generated on",
        issuedOn: "Issued on",
        template: "Template",
        dps: "Deposit protection scheme",
        additionalClause: "Additional clause",
        embedComplianceDocs: "Embed compliance documents into the contract",
        embedComplianceDocsTooltip:
          "These documents are always attached to the email that is sent containing the contract",
        additionalDocuments: "Additional documents",
        generateButton: "Generate contract",
        generatingText: "We are generating your document...",
        contractFor: "Contract for ",
        validationError: "This template has been updated recently. Please ",
        validationErrorCta: "update your contract set up here",
        errors: {
          title: "Contract generation failed.",
          line1: "Check the optional sections chosen at ",
          setup: "set up",
          line2: ", including any guarantor options.",
          line3: "Check the right ",
          people: "people",
          line4: "are on the stay.",
        },
        errored:
          "There was an error issuing the contract. Please delete and try again, or contact support.",
        peopleBlocker: {
          unapprovedTitle: "Unapproved user(s)",
          unapprovedDescription:
            "There are residents on this stay who are not approved",
          missing: "There are no residents on this stay",
          missingContactTitle: "Missing contact details",
          missingContactDescription:
            "There is no contact address for this user",
        },
        signatoryBlocker: "Signatory details are required",
        bankAccountBlocker: {
          upfront:
            "There are no bank details for this stay. If you use this in the selected template you'll need to add them",
          ongoing:
            "There are no bank details for this stay. If you use this in the selected template you'll need to add them",
        },
        signingTitle: "Digital signing",
        viewSignedContract: "View signed contract",
        people: {
          residents: "Residents",
          guarantors: "Guarantors",
          counterSigners: "Counter Signers",
        },
        issueCheckboxLabel:
          "I confirm that the generated contract looks correct",
        reviewStaySummaryCheckbox: {
          start: "I confirm the ",
          link: "stay details",
          end: " are correct",
        },
        previewModal: {
          previewAndIssue: "Preview and Issue",
          previewContractFor: "Preview contract for %{name}",
          issueButton: "Issue",
          confirmDocumentLooksOkCheckbox:
            "I confirm that the generated contract looks correct",
        },
        generate: {
          title: "Generate Document",
          modal: {
            term: "Term details",
            button: "Confirm and %{action}",
          },
        },
        deleteModal: {
          title: "Delete contract",
          description: "Are you sure you want to delete this contract?",
        },
        archiveModal: {
          title: "Delete generated contract",
          description: "Are you sure you want to delete?",
          description2:
            "If this contract has been issued, it will no longer be possible to sign the document",
        },
        missingDataBlocker: {
          description: "There are certificates missing on the property",
          values: {
            certificates: "Certificates",
            gasCertificate: "Gas certificate",
            epc: "EPC",
            electricalCertificate: "Electrical certificate",
          },
        },
        uploadValidation: {
          signatureTag: {
            title: "Signature tags missing",
            description:
              "The signatures tags don't match the number on the downloaded file. Please rectify or download to start again.",
          },
          uploadFailed: {
            title: "File upload error",
            description:
              "Something went wrong. Please try uploading again. Please ensure you are uploading a .docx file.",
          },
        },
      },
    },
    staysCount: {
      one: "%{count} stay found",
      other: "%{count} stays found",
    },
    stayList: {
      noUsers: "No resident available on stay",
      header: {
        started: "Current stay",
        inProgress: "New stays in progress",
        ended: "Finished stays",
        declined: "Declined stays",
      },
      status: {
        started: "Active",
        inProgress: "In progress",
        ended: "Ended",
        declined: "Declined",
      },
    },
    people: {
      addResident: "Add a resident",
      addGuarantor: "Add a guarantor",
      addOccupier: "Add a legal occupier",
      submitAddResident: "Add resident",
      userAlreadyExistsOnStay:
        "The user you're trying to add already exists on this stay.",
      submitAddGuarantor: "Add guarantor",
      submitAddOccupier: "Add legal occupier",
      editResident: "Edit a resident",
      editGuarantor: "Edit a guarantor",
      editOccupier: "Edit a legal occupier",
      form: {
        label: {
          firstName: "First name",
          lastName: "Last name",
          email: "Email",
          phoneNumber: "Phone number",
        },
      },
      view: {
        title: "Actions",
      },
      actions: {
        inviteToPaperwork: {
          title: "Invite to stay",
          content:
            "Invite %{name} to join this stay. Once they have accepted, %{leadResidentName} will be able to fill in their details for referencing.",
          contentIndividual:
            "Invite %{name} to join this stay. Once they have accepted, they will be able to fill in their details for referencing.",
          contentNotAccepted: "Invite not accepted • Sent %{inviteSentAt}",
          contentNotAcceptedIndividual:
            "Invite not accepted • Sent %{inviteSentAt}",
          contentAccepted:
            "%{leadResidentName} is responsible for completing paperwork for everyone",
          contentAcceptedIndividual:
            "%{name} is responsible for completing their own paperwork",
          action: {
            send: "Send invitation",
            resend: "Resend invitation",
          },
          inviteToPaperworkError:
            "Sorry, 'Invite to stay' failed. Please try again in a moment",
          inviteToPaperworkSuccess:
            "We've sent %{name} an invite to the stay. Once accepted, %{leadResidentName} can update their details for referencing.",
          inviteToPaperworkSuccessIndividual:
            "We've sent %{name} an invite to the stay. Once accepted, they can update their details for referencing.",
          status: {
            not_sent: "Not sent",
            invite_sent: "Invite sent",
            invite_accepted: "Invite accepted",
            paperwork_submitted: "Paperwork complete",
          },
        },
      },
    },
    references: {
      addReference: "Create reference request",
      viewRequest: "View request",
      viewFinalReport: "View final report",
      viewTitle: "View reference request",
      detailsTitle: "Reference request details",
      resultsTitle: "Referencing results",
      noReferences: "No references yet",
      referencingDate: "Referencing %{date}",
      results: "%{name} results",
      interimReport: "Interim report",
      finalReport: "Final report",
      enquiryId: "Enquiry id: %{id}",
      submitAddReferenceRequest: "Create reference request",
      createCta: "Create a reference",
      allReferenced:
        "You cannot create a new reference as all users on the stay already have one",
      form: {
        label: {
          user: "Who is this for?",
          term: "What term is this for?",
        },
      },
      errors: {
        term_id: "Term is required",
        user_id: "User is required",
      },
      details: {
        name: "Name",
        term: "Term",
        address: "Property address",
      },
      letAllianceDetails: {
        intro:
          "A more detailed update on referencing progress is available via the ",
        loginDetails: "Your login details are below.",
        dashboardLink: "Let Alliance dashboard ",
        footer:
          "If you have any questions about an ongoing or completed application contact Let Alliance by ",
        footerPhoneJoiner: " or phone on ",
        accNumber: "Agent account number:",
        accUsername: "Username:",
        phone: "01244207040",
        quoteEnquiry:
          " You will need to quote your enquiry ID and Residently as the account when calling or emailing.",
      },
      listItems: {
        status: {
          processing: "In progress",
          complete: "Completed",
          created: "Not started",
          pending_3rd_party_submission: "Not started",
        },
      },
      actions: {
        createReference: {
          title: "Referencing",
          content:
            "You can now reference this person by creating a reference request",
          action: "Create reference",
          status: {
            created: "Created",
          },
        },
      },
      deleteModal: {
        title: "Delete reference request",
        confirmation: "Are you sure you want to delete this reference request?",
      },
    },
    terms: {
      addTerm: "Add a term",
      addInSitu: "Add an in situ stay",
      submitAddTerm: "Add term",
      submitAddInsituStay: "Add in situ stay",
      editTerm: "Edit a term",
      rollingLatest:
        "To add a new term, you'll first need to add an end date to the latest term",
      form: {
        dayOfMonth: "%{day} of the month",
        label: {
          startDate: "Start date",
          leaseLength: "Lease length",
          endDate: "End date",
          residentsBreakDate: "Resident break date",
          landlordBreakDate: "Landlord break date",
          residentsBreakNotice: "Resident notice in months",
          landlordBreakNotice: "Landlord notice in months",
          rent: "Rent per month",
          amount: "Amount",
          paymentDayOfMonth: "Payment day",
          holdingDeposit: "Holding deposit",
          upfrontRentMonths: "Upfront rent in months",
          upfrontRentAmount: "Upfront rent",
          securityDeposit: "Security deposit",
        },
        placeholder: {
          paymentDayOfMonth: "Day",
          holdingDeposit: "Holding deposit amount",
          upfrontRentAmount: "Upfront rent amount",
          securityDeposit: "Security deposit amount",
        },
        error: {
          beforeStartDate: "Date should not be before start date",
          afterEndDate: "Date should not be after end date",
          rent: {
            invalid: "Rent amount must be at least £1",
          },
          holdingDeposit: {
            invalid: "Holding deposit must be populated, even if it's £0",
          },
          securityDeposit: {
            invalid: "Security deposit must be populated, even if it's £0",
          },
          upfrontRentAmount: {
            invalid: "Upfront rent must be populated, even if it's £0",
          },
          startDate: {
            missing: "Start date is required",
          },
          leaseLength: {
            invalidStartDate:
              "A valid start date is needed to calculate the dates below",
          },
          charges: {
            monthly: {
              tooLow: "Must be at least £1",
            },
            upfront: {
              tooLow: "Must be populated, even if it's £0",
            },
          },
        },
        tooltip: {
          paymentDayOfMonth:
            "This will default to the start day of the stay, or your organisation's custom day if you have one set up",
        },
        insituAlert:
          "You have successfully added an insitu stay, now you need to add the people to this stay",
      },
      listItems: {
        ofMonth: "%{day} of month",
        inMonths: {
          one: "%{count} month",
          other: "%{count} months",
        },
        missing: {
          expected: "-",
          optional: "None",
        },
        status: {
          upcoming: "Upcoming",
          current: "Current",
          ended: "Ended",
        },
        detailTitles: {
          residentsBreakDate: "Resident break date",
          rent: "Rent per month",
          otherCharges: "Other monthly charges",
        },
      },
      deleteModal: {
        title: "Delete Term",
        confirmation: "Are you sure you want to delete this term?",
        onlyTerm:
          "This term cannot be deleted, there has to be at least one term per stay",
      },
    },
    signatory: {
      signatory: "Landlord & signatory",
      addSignatory: "Add a landlord",
      addLandlordErrorModal: {
        title: "Add landlord",
        message:
          "There can only be two landlords on this stay. To add another landlord please remove one first.",
      },
      editSignatory: "Edit",
      signatoryDetailsForm: {
        title: "Landlord and signatory details",
        landlord: "Landlord",
        contractSignatory: "Contract signatory",
        signatoryName: "Signatory name",
        signatoryEmail: "Signatory email",
        clientLegalAddress: "Address",
        managedFor: "Full name / Company",
        confirm: "Confirm",
        cancel: "Cancel",
        save: "Save changes",
        remove: "Remove",
        addSecondLandlord: "+ Add secondary landlord",
        tooltips: {
          landlord:
            "This is the full legal name of the landlord that will be used in the contract",
          contractSignatory:
            "This is the person who will countersign the contract. This can be the landlord or another person with power of attorney",
        },
        errors: {
          clientEmail: "Valid email address is required",
          clientSignatory: "Name is required",
          clientLegalAddress: "Address is required",
          managedFor: "Full name / Company is required",
        },
      },
    },
    payments: {
      upfrontDetails: "Upfront bank details",
      buttons: {
        add: "Add bank details",
        upfrontBankDetails: "Upfront bank details",
        ongoingBankDetails: "Ongoing bank details",
      },
      modal: {
        add: {
          upfrontTitle: "Add upfront bank details",
          upfrontMessage:
            "Upfront payment details already exist on this stay. If you want to change these details, you can edit the existing record",
          ongoingTitle: "Add ongoing bank details",
          ongoingMessage:
            "Ongoing payment details already exist on this stay. If you want to change these details, you can edit the existing record",
        },
      },
      listItems: {
        buttons: {
          edit: "Edit",
        },
        listTitles: {
          upfrontPaymentDetails: "Upfront payment bank details",
          ongoingPaymentDetails: "Ongoing payment bank details",
        },
        detailTitles: {
          accountNumber: "Account number",
          sortCode: "Sort code",
          iban: "IBAN",
          swift: "Swift/BIC",
        },
      },
      paymentDetailsForm: {
        titles: {
          addUpfront: "Bank details for upfront payment",
          editUpfront: "Edit bank details for upfront payment",
          ongoing: "Bank details for ongoing rent payments",
        },
        submitText: "Save changes",
        labels: {
          accountName: "Account name",
          accountNumber: "Account number",
          sortCode: "Sort code",
          iban: "IBAN (Optional)",
          swift: "Swift / BIC (Optional)",
        },
        errors: {
          required: {
            accountName: "Account name required",
            accountNumber: "Account number required",
            sortCode: "Sort code required",
          },
          format: {
            accountNumber: "Account number should be 8 digits",
            sortCode: "Sort code should be 6 digits",
          },
        },
      },
    },
    residents: {},
    guarantors: {
      contactDetailsTitle: "Contact details",
      mobile: "Phone number",
      email: "Email address",
    },
    reassignLeadResident: {
      title: "Reassign lead resident",
      description: [
        "The lead resident is responsible for completing the offer and then uploading details and documents for all residents and guarantors on the application.",
        "Reassigning a new lead resident will email the new lead resident with a link to create an account and complete the relevant paperwork.",
      ],
      label: "Please select a resident",
      errors: {
        required: {
          nominatedLeadResident: "New lead resident required",
        },
      },
    },
    reassignProperty: {
      title: "Reassign property",
      description:
        "Please note that if you are making this change after the residents have submitted paperwork, you will need to contact Let Alliance to inform them of the change in the property address and to request that they update the referencing reports if they've already been completed.",
      label: "Select property to reassign application to",
      searchPlaceholder: "Search for postcode or address",
      noProperty: "Please select a property",
      success: "Successfully reassigned property on this application",
    },
  },
  applications: {
    addApplication: "Add a new application",
    whichProperty: "Which property is this application for?",
    searchPlaceholder: "Search for postcode or address",
    noProperty: "Please select a property",
    empty: "No applications found",
    filters: {
      portfolio: {
        label: "Portfolio",
      },
    },
    tabs: {
      newOffers: "New offers",
      approvedOffers: "Approved offers",
      recentlyCompleted: "Recently completed",
      declined: "Declined",
    },
  },
  preQualification: {
    addPreQualification: "Pre Qualification Information",
    move_in_date: "When can they move in from?",
    people_count: "How many people will be living at the property?",
    annual_household_income: "What is the annual household income?",
    main_sources_of_income: "What is the main source of income?",
    guarantor_available: "If needed, can they provide a guarantor?",
    guarantor_income: "Gurantor income",
    pay_upfront: "If needed, can they pay 6 months upfront?",
    adverse_credit: "Does anyone have any adverse credit?",
    optional: " (Optional)",
    errors: {
      required: "This is a required question",
    },
    editThresholds: "Edit pre-qualification thresholds",
    thresholds: {
      move_in_date: "Move in date (days from available from date)",
      people_count: "Max number in household",
      annual_household_income: "Annual household income required",
      adverse_credit: "Require clear credit history?",
    },
  },
  viewingEvent: {
    form: {
      startDate: "Date",
      startTime: "Start time",
      endTime: "End time",
    },
    fields: {
      property: "Property",
      startDate: "Starts at",
      endDate: "Ends at",
    },
    sectionTitle: "Viewing event for",
    date: "Date",
    startTime: "Start time",
    endTime: "End time",
    viewings: "Viewings",
    noViewings: "No viewings found",
  },
  viewing: {
    details: "Details",
    lead: "Lead",
    preQual: "Pre Qualification",
    preQualEmpty: "No pre qualification answers",
    cancellation: "Cancellation",
    notes: "Notes",
    fields: {
      name: "Lead name",
      email: "Lead email",
      mobile: "Lead phone",
      property: "Property",
      date: "Viewing date and time",
      cancellationReason: "Cancellation reason",
      cancelledAt: "Cancelled on",
      placeholder: {
        date: "DD/MM/YYYY",
      },
      preQualificationAnswers: {
        incomeSourceTypes: {
          employed: "Employed",
          savings: "Savings",
          self_employed: "Self-employed or Freelance",
          pension: "Pension",
          student: "Student",
          unemployed: "Unemployed",
          benefits: "Benefits",
        },
        moveInDate: "Move in date",
        peopleCount: "Number in household",
        annualHouseholdIncome: "Annual household income",
        mainSourcesOfIncome: "Main sources of income",
        guarantorAvailable: "Guarantor available",
        guarantorIncome: "Guarantor income",
        payUpfront: "Could pay 6 months upfront",
        adverseCredit: "Adverse credit",
      },
      messageToResident: "Message to resident",
      viewingEvents: "Select an event",
    },
    form: {
      name: "Resident name",
      email: "Resident email",
      phone: "Resident phone number",
      property: "Property",
      startDateTime: "When is the viewing?",
      moveInDate: "When is the move in date?",
      peopleCount: "How many people will be living in the property?",
      annualHouseholdIncomeAmount: "What is the annual household income?",
      amount: "Amount",
      mainSourcesOfIncome: "Main sources of income?",
      viewingEvents: "Viewing events",
      noViewingEvents: "There are no viewing events for this property",
      errors: {
        date: "Please select viewing date",
        hour: "Please select viewing hour",
        minutes: "Please select viewing minutes",
      },
      placeholder: {
        peopleCount: "People count",
      },
    },
    cancelModal: {
      cancelTitle: "Cancel viewing",
      declineTitle: "Decline viewing",
      whoCancelled: {
        title: "Who cancelled the viewing?",
        options: {
          cancelledByResident: "Resident",
          cancelledByPartner: "Partner",
        },
      },
      reasonForCancellation: {
        title: "Reason for cancellation",
        label: "Please select a reason",
      },
      reasonForDeclining: {
        title: "Reason for declining",
        label: "Please select a reason",
      },
      messageToResident: {
        title: "Message to the resident",
        description: "This will be sent to the resident via email",
        label: "Start typing a message to the resident",
      },
      requestError: "Something went wrong, please try again",
      reasons: {
        noLongerInterested: "No longer interested",
        foundAnotherProperty: "Found another property",
        couldNotMakeIt: "Couldn't make the appointment",
        noReason: "Cancelled with no reason",
        didNotAttend: "Resident didn't attend the viewing",
        oversubscribed: "Property is oversubscribed for viewings",
        failedPrequalification: "Resident didn't hit pre-qualification",
        propertyNotSuitable:
          "Property not suitable following pre-qualification chat",
      },
      responses: {
        noLongerInterested:
          "You advised us that you are no longer interested in the property.",
        foundAnotherProperty:
          "You advised us that you are no longer interested in the property.",
        couldNotMakeIt:
          "You advised us that you couldn't make the appointment.",
        noReason: "",
        didNotAttend:
          "Your viewing has been cancelled as you didn't attend the arranged viewing",
        oversubscribed:
          "Unfortunately, due to high demand for this property, we are unable to accept your viewing request at this time.",
        failedPrequalification:
          "Unfortunately, due to high demand for this property, we are unable to accept your viewing request at this time.",
        propertyNotSuitable:
          "Unfortunately, due to high demand for this property, we are unable to accept your viewing request at this time.",
      },
      errors: {
        cancelledBy: "Please choose an option",
        cancellationReason: "Please select a reason",
      },
      cancelViewingCta: "Cancel viewing",
      declineViewingCta: "Decline viewing",
      keepViewingCta: "Keep viewing",
    },
    acceptCta: "Accept",
    heading: "Viewing details",
    sectionTitle: "Viewing for",
    reschedule: "Reschedule",
  },
  lead: {
    spotlight: "Spotlight",
    overview: "Overview",
    emailLead: "Email lead",
  },
  stayProgress: {
    declined: "Declined",
    declinedAlert: "Declined stays cannot progress further",
    status: "Progress",
    next: "Next task:",
    completedBy: "To be completed by:",
    summary: "View stay details",
  },
  login: {
    help: "Need help? Get in touch",
    index: {
      title: "Log in to your account",
      label: "Email address",
      error: {
        invalid: "Email address must contain an @ symbol and a .",
        unknown:
          "Please check you're using the email address registered with us, or let us know if there's still an issue.",
      },
      signup: {
        text: "New to Residently?",
        ctaText: "Get started today",
      },
    },
    confirmation: {
      title: "Check your inbox",
      emailInfo: "We sent an email to you at",
      magicLink: "It has a magic link that'll log you in.",
      notReceived: "Haven't received an email?",
      useCode: "I have a sign in code",
    },
    notReceived: {
      title: "Still not received your email?",
      listItem1:
        "Check your spam folder, if our email has ended up there please mark it as 'not spam'",
      listItem2: "Wait 5 minutes, there can sometimes be a delay",
      listItem3: "Resend the email using the button below",
      listItem4: "If you're still having issues logging in, email us at ",
      emailResent:
        "Another email with a link to log in has been sent to %{email}",
    },
    code: {
      errorTitle: "Sorry, login failed",
      linkError: "Your sign in link didn't work",
      tryAgain: "Send a new link",
      useCodeInstead: "Enter your code instead",
    },
    codeEntry: {
      title: "Enter your code",
      label: "Code",
      summary:
        "This is the code at the bottom of the Sign in email we've sent you",
      error: "The code entered is incorrect",
      generalError: "There is a problem, please go back and try again",
      wrongCodeError:
        "That code isn't right, please check your email and try again",
      expiredError: "That code has expired, go back and try again",
      blockedError: "Your account has been blocked, please contact Residently",
    },
    complete: {
      error: "We were unable to retrieve your details:",
      loading: "We're just retrieving your details...",
    },
    buttons: {
      login: "Log in",
      back: "Go back",
      resend: "Resend email",
    },
  },
  properties: {
    pageTitleError: "Error loading details",
    pageTitle: "Properties",
    title: "Properties",
    addProperty: "Add a property",
    supportDetails: "Support details",
    header: {
      furnished: "Furnished",
      unfurnished: "Unfurnished",
      bedrooms: "%{count} Bed",
    },
    propertyCount: {
      one: "%{count} property found",
      other: "%{count} properties found",
    },
    stateFilters: {
      label: {
        zero: "Status",
        one: "Status (1)",
        other: "Status (%{count})",
      },
      void: {
        label: "Void",
        value: "void",
      },
      let: {
        label: "Let",
        value: "let",
      },
    },
    extraInfoForm: {
      title: "Add image (Optional)",
      caption:
        "Having an image will help improve the resident experience on paperwork, the app and emails.",
      propertyIdLabel: "Do you have a property ID?",
      propertyIdTooltip: "This is your internal property ID",
      propertyId: "Property ID (Optional)",
      imageError: "Image upload failed, please try again",
      imageRetry: "RETRY",
    },
    addressForm: {
      addressErrors: {
        houseNameNumber: "House name OR number required",
        streetLine1: "Street line 1 required",
        city: "City required",
        postcode: "Postcode required",
      },
    },
    label: {
      address: "Property address",
      details: "Property details",
      extraInfo: "Extra info",
      landlord: "Landlord",
    },
    supportDetailsForm: {
      title: "Support details",
      contactName: "Contact name",
      email: "Email address",
      phone: "Telephone number",
      errors: {
        email: {
          required: "Email address required",
          format: "Email address is invalid",
        },
        phone: "Telephone number required",
        contactName: "Contact name required",
      },
    },
  },
  leads: {
    addLeadTitle: "Add a lead",
    noData: "There is no further information available for this lead right now",
    noLeads: "No leads found",
    addLead: "+ Add a lead",
    noPerson: "Please select a person",
    noProperty: "Please select a property",
    inviteSent: "Invite sent %{date}",
    offerIntentHeader: "Offer intent",
    viewingFeedbackHeader: "Viewing feedback",
    viewingFeedbackOverview: {
      labels: {
        reason: "Main reason for not proceeding",
        comments: "Comments",
      },
      reasons: {
        tooSmall: "Property is too small",
        tooLarge: "Property is too large",
        layout: "Layout of the property",
        quality: "Quality/finish of the property",
        price: "Price",
        location: "Location/area",
        other: "Other",
      },
    },
    leadCreatedAt: "Lead created",
    viewingFeedbackInsights: {
      submitted: "Submitted",
      lead: "Lead",
      reason: "Reason",
      comments: "Comments",
    },
    applicationActionCard: {
      content: "This lead has an application in progress",
      viewApplication: "View application",
      completeSetup: "Complete setup",
    },
    enquiryHeader: "Pre-qualification / enquiry",
    actions: {
      startApplication: "Start application",
      viewApplication: "View application",
      completeSetup: "Complete setup",
      invite: {
        one: "Send invite",
        other: "Resend invite",
      },
      close: "Close lead",
      addViewing: "Add a viewing",
    },
    tabs: {
      overview: "Overview",
      otherLeads: "Leads",
      viewings: "Viewings",
      notes: "Notes",
    },
    sidebar: {
      title: "Leads",
      label: {
        preQualified: "Pre-qualified",
      },
    },
    closeLeadModal: {
      title: "Set the lead to closed?",
      description1: "We've marked this viewing as a no-show",
      description2: " Do you want to set this lead to closed status now?",
      noThanks: "No thanks",
      close: "Set lead to closed",
      alert: "Lead has been set to closed",
    },
    filters: {
      preQualified: {
        label: {
          zero: "Pre-qualification",
          one: "Pre-qualification (1)",
          other: "Pre-qualification (%{count})",
        },
        items: {
          notCompleted: {
            label: "Not completed",
            value: "not_completed",
          },
          passed: {
            label: "Passed",
            value: "passed",
          },
          failed: {
            label: "Failed",
            value: "failed",
          },
        },
      },
      leadStatus: {
        label: {
          zero: "Status",
          one: "Status (1)",
          other: "Status (%{count})",
        },
        items: {
          new: {
            label: "New",
            value: "new",
          },
          enquiry: {
            label: "Enquiry",
            value: "enquiry",
          },
          viewing_cancelled: {
            label: "Viewing Cancelled",
            value: "viewing_cancelled",
          },
          viewing_arranged: {
            label: "Viewing Booked",
            value: "viewing_arranged",
          },
          viewing_requested: {
            label: "Viewing Requested",
            value: "viewing_requested",
          },
          closed: {
            label: "Closed",
            value: "closed",
          },
          invited: {
            label: "Invited",
            value: "invited",
          },
          awaiting_feedback: {
            label: "Awaiting Feedback",
            value: "awaiting_feedback",
          },
          feedback_received: {
            label: "Feedback Received",
            value: "feedback_received",
          },
          offer_intent: {
            label: "Offer Intent",
            value: "offer_intent",
          },
          in_application: {
            label: "In application",
            value: "in_application",
          },
        },
      },
    },
    state: {
      enquiry: "Enquiry",
      new: "New",
      viewing_cancelled: "Viewing Cancelled",
      viewing_arranged: "Viewing Booked",
      viewing_requested: "Viewing Requested",
      not_proceeding_viewed: "Not Proceeding",
      has_question: "Has Question",
      reach_out_later: "Reach Out Later",
      long_term_tenant: "Long Term Tenant",
      viewing_no_show: "Viewing No-Show",
      likely: "Likely",
      unlikely: "Unlikely",
      not_proceeding: "Not Proceeding",
      awaiting_feedback: "Awaiting Feedback",
      feedback_received: "Feedback Received",
      awaiting_reply: "Awaiting Reply",
      awaiting_decision: "Awaiting Decision",
      archived: "Archived",
      no_response: "No Response",
      closed: "Closed",
      invited: "Invited",
      offer_intent: "Offer Intent",
      in_application: "In application",
    },
    addPerson: "+ Add new person",
    searchPerson: "Search for person",
    whichProperty: {
      label: "Which property is this lead for?",
      placeholder: "Search for postcode or address",
    },
    whichPropertyToLet: {
      label: "Which property to let is this lead for?",
      placeholder: "Search for postcode or address",
      tooltip: {
        firstLine: "Select the property to let for this lead.",
        secondLine:
          "If you can't find the property you're looking for, first add it as a new property to let.",
      },
      addNew: "+ Add new property to let",
    },
    whichPerson: {
      label: "Which person is this lead for?",
      placeholder: "Search for name or email",
    },
    personDetailsForm: {
      errors: {
        firstName: "First name is required",
        lastName: "Last name is required",
        email: {
          missing: "Email address is required",
          invalid: "Please enter a valid email address",
        },
        phone: "Phone number is required",
      },
    },
  },
  offerIntents: {
    fields: {
      offerIntentQuestions: {
        offerPrice: "Offer price",
        suggestedMoveInDate: "Suggested move in date",
        preferredLeaseLength: "Lease length",
        adultCount: "Number of adults",
        childrenCount: "Children (under 18)",
        offerComments: "Offer comments",
      },
      offerIntentAnswers: {
        leaseLengthOption: "%{answer} months",
      },
      submitted: "Submitted %{date}",
    },
    status: {
      awaitingFeedback: "Awaiting feedback",
      notProceeding: "Rejected",
      proceeding: "Approved",
      submittedFeedback: "Submitted",
    },
    acceptCta: "Accept and start application",
    rejectInviteNew: "Reject and invite new offer",
    rejectModal: {
      rejectTitle: "Reject offer intent",
      reasonForRejecting: {
        title: "Reason for rejecting",
        label: "Please select a reason",
      },
      reasons: {
        offerPrice: "Offer price",
        moveInDate: "Move in date",
        leaseLength: "Lease length",
        anotherOffer: "Accepted another offer",
        leadWithdrew: "Lead withdrew their offer",
        other: "Other",
      },
      messageToLead: {
        title: "Message to the lead",
        description: "This will be sent to the lead via email",
        resubmitIntent:
          "Let the lead know why the offer was rejected or what they need to offer to get it accepted",
        label: "Start typing a message to the lead",
      },
      closeLead: "Set lead to closed?",
      rejectOfferIntentCta: "Reject offer intent",
      resubmitCTA: "Reject and invite new offer",
      responses: {
        offerPrice: "Your offer price was not enough at this time",
        moveInDate:
          "Your proposed move in date doesn't meet what we are looking for at this time",
        leaseLength:
          "Your proposed lease length doesn't meet what we are looking for at this time",
        anotherOffer:
          "Unfortunately we've already accepted an offer on this property",
        leadWithdrew:
          "You advised us you no longer wanted to proceed with the offer",
        other: "",
      },
      errors: {
        rejectionReason: "Please select a reason",
        rejectionMessage: "Please enter a message",
        requestError: "Something went wrong, please try again",
      },
    },
  },
  lets: {
    pageTitle: "Properties to let",
    createdAt: "Let created:",
    create: "Add property to let",
    empty: "Add a property to let to get started",
    manage: {
      markComplete: "Mark as complete",
      viewingAvailability: "Manage viewing availability",
      listing: "Manage listing",
      archive: "Archive",
    },
    notices: {
      maxViewingsReached: {
        title: "Maximum viewings have been reached",
        description:
          "Any new leads won't be able to book a viewing. You may want to consider:",
        stepsToConsider: [
          "Increasing the maximum in settings and unpausing viewings",
          "Removing the rightmove listing",
        ],
      },
    },
    viewingAvailability: {
      title: "Viewing availability",
      state: {
        paused: "Viewings paused",
      },
    },
    tabs: {
      index: {
        inProgress: "In progress",
        completed: "Completed",
      },
      show: {
        insights: "Overview",
        settings: "Settings",
      },
    },
    listings: {
      title: "Listing",
    },
    insights: {
      empty: "Insights will appear once listing is live and enquiries are made",
      leads: {
        title: "Leads",
        breakdown: {
          all: "All leads",
          passed: "Pre-qualification passed",
          failed: "Pre-qualification failed",
          notCompleted: "Pre-qualification not completed",
          booked: "Upcoming viewings",
          offerIntents: "Offers",
          application: "Application",
        },
      },
      rightmove: {
        title: "Rightmove",
        duration: {
          all: "All time",
          month: "Month",
          week: "Week",
        },
        total: {
          summary: "Summary views",
          detail: "Detail views",
        },
        notEnoughData:
          "Data will display once your listing has been live on Rightmove for 3 days",
        placeholder:
          "We’re working on bringing this lets Rightmove performance data here. Watch this space!",
      },
      comparables: {
        title: "Comparables",
      },
      demandFunnel: {
        title: "Demand funnel",
      },
    },
    settings: {
      formError: "An error occured while updating",
      maxNumberOfViewings: {
        label: "Maximum number of viewings",
        description:
          "If enabled, once viewing count has been reached viewings will be automatically paused and any new leads passing pre-qualification won't be able to book a viewing.",
      },
    },
    add: {
      title: "Add property to let",
      property: {
        none: "Please select a property",
        label: "Which property is this let for?",
        placeholder: "Search for postcode or address",
      },
    },
    actions: {
      title: "Actions",
      viewingAvailability: {
        title: "Viewing availability",
        description:
          "Set your viewing availability as individual slots or a viewing event so leads can book viewings",
        action: "Set viewing availability",
      },
      propertyListing: {
        title: "Property listing",
        description: "Create your property listing and publish to Rightmove",
        action: "Create a listing",
      },
    },
    modals: {
      completed: {
        title: "Mark as complete",
        description: [
          "This property to let has a live listing. You should consider removing the listing before marking the let as complete.",
          "Do you still want to complete the let?",
        ],
      },
      archive: {
        title: "Archive let?",
        description: [
          "Archiving the let will delete the let from your dashboard and you will no longer be able to access any data.",
          "If this let is complete you should mark it as completed instead.",
          "Are you sure you want to archive this let?",
        ],
      },
    },
  },
  renewals: {
    pageTitle: "Renewals",
    empty: "There's no upcoming renewals just yet",
    headers: {
      address: "Address",
      people: "Residents",
      endDate: "End date",
    },
  },
  stayReviews: {
    pageTitle: "Renewals",
    stayEnding: "Stay ending: ",
    empty: "There's no renewal reviews just yet",
    backToList: "Back to reviews",
    introductionTitle: "Renewal Reviews",
    introductionText: [
      "Get a holistic view of your operations and make business decisions with confidence.",
      "Get rich insight in your customers experience to help you understand their position.",
      "Get real-time comparable pricing and recommendations to maximise returns.",
      "To enable renewal reviews, reach out to a member of the team.",
    ],
    unavailableTitle: "Renewal overview unavailable",
    unavailableText: [
      "The review overview is not currently available for this property.",
      "Please reach out to a member of the team if you continue to see this.",
    ],
    ending: {
      label: "Stay ending",
      ended: "Ended %{when}",
      na: "Unknown",
    },
    residentIntention: {
      label: "Resident intention",
      values: {
        awaiting_feedback: "Awaiting feedback",
        leaving: "Leaving",
        renewing: "Renewing",
        undecided: "Undecided",
      },
    },
    ownerIntention: {
      label: "Owner intention",
      values: {
        awaiting_confirmation: "Awaiting confirmation",
        selling: "Selling",
        renewing: "Renewing",
        reletting: "Reletting",
        reletting_minor_repair: "Reletting (Minor repair needed)",
        major_refurbishment_required: "Major refurbishment required",
      },
    },
    status: {
      label: "Status",
      values: {
        not_started: "Not started",
        active: "In progress",
        done: "Completed",
        closed: "Closed",
      },
    },
    tabs: {
      overview: "Overview",
      notes: "Notes",
    },
    manage: {
      changeOwnerIntention: {
        label: "Change owner intention",
        errors: {
          missing: "Please select a new intention",
        },
      },
      changeStatus: {
        label: "Change status",
        errors: {
          missing: "Please select a new status",
        },
      },
      closeReview: {
        label: "Close review",
      },
    },
    modals: {
      changeOwnerIntention: {
        title: "Change owner intention",
        description: ["Select a new intention for the owner"],
        label: "New intention",
      },
      changeStatus: {
        title: "Change status",
        description: ["Select a new status for this review"],
        label: "New status",
      },
      close: {
        title: "Close review",
        description: ["Are you sure you want to close this review?"],
      },
    },
    filters: {
      status: {
        label: "Status",
        items: {
          not_started: {
            label: "Not started",
            value: "not_started",
          },
          active: {
            label: "In progress",
            value: "active",
          },
          done: {
            label: "Completed",
            value: "done",
          },
          closed: {
            label: "Closed",
            value: "closed",
          },
        },
      },
      residentIntention: {
        label: "Resident intention",
        items: {
          awaiting_feedback: {
            label: "Awaiting feedback",
            value: "awaiting_feedback",
          },
          undecided: {
            label: "Undecided",
            value: "undecided",
          },
          renewing: {
            label: "Renewing",
            value: "renewing",
          },
          leaving: {
            label: "Leaving",
            value: "leaving",
          },
        },
      },
      ownerIntention: {
        label: "Owner intention",
        items: {
          awaiting_confirmation: {
            label: "Awaiting confirmation",
            value: "awaiting_confirmation",
          },
          selling: {
            label: "Selling",
            value: "selling",
          },
          renewing: {
            label: "Renewing",
            value: "renewing",
          },
          reletting: {
            label: "Reletting",
            value: "reletting",
          },
          reletting_minor_repair: {
            label: "Reletting (Minor repair needed)",
            value: "reletting_minor_repair",
          },
          major_refurbishment_required: {
            label: "Major refurbishment required",
            value: "major_refurbishment_required",
          },
        },
      },
    },
  },
  listings: {
    pageTitle: "Listings",
    title: "Listings",
    addListing: "Add listing",
    listingsCount: {
      one: "%{count} listing found",
      other: "%{count} listings found",
    },
    error: "There was an error loading your listings: %{error}",
    stateFilters: {
      label: {
        zero: "Status",
        one: "Status (1)",
        other: "Status (%{count})",
      },
      draft: {
        label: "Draft",
        value: "draft",
      },
      removing: {
        label: "Removing",
        value: "removing",
      },
      publishing: {
        label: "Publishing",
        value: "publishing",
      },
      published: {
        label: "Published",
        value: "published",
      },
    },
    labels: {
      displayAddress: "Display address",
      propertySize: "Property size",
      propertyType: "Property type",
      bedrooms: "Bedrooms",
      bathrooms: "Bathrooms",
      furnishing: "Furnishing",
      propertyDescription: "Summary description",
      areaDescription: "Full description",
      price: "Price per month",
      availableFrom: "Available to move in from",
      publishOn: "Publish listing on",
      virtualTour: "Virtual tour URL",
      sqft: "sqft",
      councilTaxBand: "Council tax band",
      firstPublished: "First published at",
    },
    sections: {
      basics: "Basics",
      photos: "Photos",
      floorplanAndEpc: "Floorplan & EPC",
      virtualTour: "Video/Virtual tour",
      availability: "Availability & pricing",
      keyFeatures: "Key features",
      portals: "Portals",
      preQualificationQuestions: "Pre-qualification Thresholds",
    },
    portals: {
      residently: "Residently",
      rightmove: "Rightmove",
      notLive: "Links will be available once the listing is live",
    },
    emptyData: {
      floorplan: "No floorplan uploaded",
      epc: "No EPC uploaded",
      video: "No video uploaded",
      keyFeatures: "No key features selected",
    },
    media: {
      epcAlt: "EPC preview",
      floorplanAlt: "Floorplan preview",
    },
  },
  listingsAdd: {
    title: "Add a listing",
    labels: {
      address: "Address",
      bedrooms: "Bedrooms",
      bathrooms: "Bathrooms",
    },
    cta: {
      subtitle: "Is this information correct?",
      edit: "Edit property",
      continue: "Continue",
    },
    whichProperty: {
      label: "Which property is this lead for?",
    },
    whichPropertyToLet: {
      label: "Which property to let is this listing for?",
      placeholder: "Search for postcode or address",
      tooltip: {
        firstLine: "Select the property to let for this listing.",
        secondLine:
          "If you can't find the property you're looking for, first add it as a new property to let.",
      },
      addNew: "+ Add new property to let",
    },
  },
  listingsView: {
    title: "Listing for",
    remove: "Remove",
    manageViewingAvailability: "Manage viewing availability",
    managePreQualThresholds: "Manage pre-qualification thresholds",
    publish: "Publish",
    cancelPublish: "Cancel publish",
    publishChanges: "Publish changes",
    setupAvailability: "Set this now",
    alerts: {
      removing:
        "Successfully updated your property listing. You will receive a confirmation when the listing is removed.",
      publish:
        "Successfully updated your property listing. You will receive a confirmation when the listing is live.",
      publishChanges:
        "Successfully updated your property listing. You will receive a confirmation when the listing changes are live.",
      publishCancel: "Successfully cancelled publishing your property listing.",
      publishError:
        "There was an error publishing, please try again, if this persists, please get in touch with our team.",
    },
    notices: {
      viewingAvailability:
        "There is no viewing availability set on this property",
      viewingsPaused: "Viewings are currently paused for this property",
      publishingUnavailable:
        "Publishing is not available for this listing, please get in touch with our team to discuss.",
    },
    lastPublishedToday: "Last published today at %{lastPublishedTime}",
    lastPublishedOn:
      "Last published on %{lastPublishedDate} at %{lastPublishedTime}",
    removeModal: {
      title: "Remove listing",
      close: "Keep listing",
      remove: "Remove listing",
      body: "Would you like to remove this listing from all portals?",
    },
    propertyTypes: {
      terraced_house: "Terraced house",
      apartment: "Apartment",
      semi_detached_house: "Semi-detached house",
      detached_house: "Detached house",
    },
    furnishing: {
      furnished: "Furnished",
      part_furnished: "Part-furnished",
      unfurnished: "Unfurnished",
    },
    publishBlockers: {
      title: "Before publishing please update:",
      displayAddress: "Display address",
      propertyType: "Property type",
      propertySize: "Property size",
      description: "Summary description",
      areaDescription: "Full description",
      price: "Price per month",
      availableFrom: "Available to move in from date",
      keyFeaturesMin: "Key features",
      keyFeaturesMax: "Key features - maximum 10",
      images: "Images",
    },
  },
  listingsEdit: {
    title: "Edit listing for",
    save: "Save",
    cancel: "Cancel",
    thresholdsConfirmationModal: {
      title: "Update pre-qualification thresholds?",
      content: {
        start:
          "You have updated the following fields which affect pre-qualification thresholds for this listing",
        end: "Do you want to update the pre-qualification thresholds now?",
      },
      triggeredBy: {
        price: "Price",
        bedrooms: "Number of bedrooms",
        availableFrom: "Available from date",
      },
      continue: "Update thresholds",
      continueNoUpdate: "Continue without updating",
    },
    placeholders: {
      displayAddress: "e.g. 15 Oxford Court, Manchester",
      propertyDescription: "300 character limit",
      areaDescription: "32000 character limit",
      virtualTour: "URL to the video",
    },
    propertyTypes: {
      terraced_house: "Terraced house",
      apartment: "Apartment",
      semi_detached_house: "Semi-detached house",
      detached_house: "Detached house",
    },
    furnishing: {
      furnished: "Furnished",
      part_furnished: "Part-furnished",
      unfurnished: "Unfurnished",
    },
    keyFeatures: {
      separateKitchen: "Separate kitchen",
      balconyPatio: "Balcony / Patio",
      underfloorHeating: "Underfloor heating",
      ensuiteBathroom: "Ensuite bathroom",
      highSpecAppliances: "High-spec appliances",
      wineFridge: "Wine fridge",
      dishwasher: "Dishwasher",
      privateIntercom: "Private intercom",
      builtinWardrobe: "Built-in wardrobe(s)",
      singleBedroom: "Single bedroom(s)",
      doubleBedroom: "Double bedroom(s)",
      loft: "Loft",
      billsIncluded: "Bills included",
      office: "Separate study / WFH area",
      bikeStorage: "Bike storage",
      concierge: "Concierge",
      lift: "Lift",
      gym: "Gym",
      paidParking: "Paid parking",
      freeParking: "Free parking",
      communalGardens: "Communal gardens",
      privateGardens: "Private gardens",
      roofTerrace: "Roof terrace",
      residentsLounge: "Residents lounge",
      petsConsidered: "Pets considered",
      newBuild: "New build",
      other: "None of the above",
    },
    customFeatures: {
      label: "Add custom",
      feature: "Key feature",
      add: "+ Add another feature",
    },
    virtualTour: {
      helpText:
        "Enter the URL of the Virtual Tour or video. If the video is from YouTube or Vimeo, the video will be automatically embedded into the listing.",
    },
    errors: {
      displayAddressLength:
        "Display address cannot be longer than 120 characters",
      propertyType: "Property type is required",
      propertySize: "Property size is required",
      description: "Summary description is required",
      descriptionLength:
        "Summary description cannot be longer than 300 characters",
      areaDescription: "Full description is required",
      areaDescriptionLength:
        "Full description cannot be longer than 32000 characters",
      price: "Price per month is required",
      availableFrom: "Available to move in from date is required",
      keyFeatures: "Key features are required",
      councilTaxBand: "Council tax band is required",
    },
  },
  viewingEvents: {
    submitViewingEvent: "Add viewing event",
    addViewingEvent: "Add a viewing event",
  },
  viewings: {
    pageTitleError: "Error loading details",
    pageTitle: "Upcoming - Viewings",
    pastPageTitle: "Past - Viewings",
    title: "Viewings",
    noViewings: "No viewings found",
    selectViewing: "Select a viewing",
    actions: {
      startApplication: "Start application",
      noShow: "Set to ‘Viewing no show’",
    },
    upcomingViewingsCount: {
      one: "%{count} upcoming viewing found",
      other: "%{count} upcoming viewings found",
    },
    pastViewingsCount: {
      one: "%{count} past viewing found",
      other: "%{count} past viewings found",
    },
    error: "There was an error loading your viewings: %{error}",
    stateFilters: {
      label: {
        zero: "Status",
        one: "Status (1)",
        other: "Status (%{count})",
      },
      requested: {
        label: "Requested",
        value: "requested",
      },
      booked: {
        label: "Booked",
        value: "booked",
      },
      cancelled: {
        label: "Cancelled",
        value: "cancelled",
      },
    },
    filterButton: "Filter",
    filteringBy: "Filtering by:",
    addViewing: "Add a viewing",
    editViewing: "Reschedule viewing",
    submitViewing: "Add viewing",
    action: {
      editViewing: "Save changes",
    },
    addToGoogleCal: "Add to Google calendar",
    addToCal: "Add to other calendar",
  },
  viewingAvailability: {
    pageTitleError: "Error loading details",
    pageTitle: "Viewing Availability",
    title: "Viewing Availability",
    propertiesCount: {
      one: "%{count} properties found",
      other: "%{count} properties found",
    },
    noProperties: "No properties to show",
    noProperty: "Please select a property",
    error: "There was an error loading your viewing availability: %{error}",
    addAvailability: "Add viewing availability",
    activeListing: "Active listing",
    whichProperty: "Which property is this availability for?",
    searchPlaceholder: "Search for postcode or address",
  },
  propertyViewingAvailability: {
    pageTitleError: "Error loading details",
    pageTitle: "Property Viewing Availability",
    title: "Viewing availability for",
    descriptions: {
      weekly: "Property available to view during these time periods each week.",
      daily:
        "Dates with specific times available for viewings, overriding the weekly pattern.",
    },
    pauseViewings: {
      true: "Unpause viewings",
      false: "Pause viewings",
    },
    columns: {
      start: "Start time",
      end: "End time",
    },
    noDailyOverrides: "You have no daily overrides created",
  },
  propertyViewingAvailabilitySettings: {
    pageTitleError: "Error loading details",
    pageTitle: "Property Viewing Availability Settings",
    labels: {
      noticePeriod: "Viewing notice (in hours)",
      viewingAvailableFrom: "Viewings available from",
    },
    helperText: {
      noticePeriod:
        "Amount of notice we need before we can schedule a viewing. If we need to give a resident 24 hours notice, set to 24",
    },
    success: "Success saving property viewing availability settings",
  },
  propertyViewingAvailabilityEdit: {
    pageTitleError: "Error loading details",
    pageTitle: "Edit Property Viewing Availability",
    descriptions: {
      weekly:
        "Enter the time periods when the property is available to view each week.",
      daily:
        "Set availability for a specific date. Enter all periods when the property is available to view, or mark as unavailable.",
    },
    labels: {
      date: "Date",
      start: "Start time",
      end: "End time",
    },
    noDailyOverrides:
      "No daily overrides, add a new row to define a new override.",
    noWeeklyAvailability: "No availability set",
    addDailyRow: "Add daily override row",
    availability: {
      available: "Available",
      unavailable: "Unavailable",
    },
    errors: {
      date: "Date is required",
      start: "Start time is required",
      end: "End time is required",
      startBefore: "Start time must be before the end time",
      endAfter: "End time must be after the start time",
      minutes: "Minutes must be either 00 or 30",
    },
    returnTo: {
      let: "Return to property let",
    },
  },
  propertyViewingAvailabilityEvents: {
    addEvent: "Add viewing event",
    emptyMessage: "No viewing events found",
    labels: {
      eventDate: "Event date",
      start: "Start time",
      end: "End time",
      viewings: "Viewings",
    },
    archive: {
      title: "Delete event",
      disabledDescription:
        "You cannot delete an event which has existing viewings booked. First reschedule or cancel the existing viewings, then you can delete the event",
      description: "Are you sure you want to delete?",
    },
  },
  payoutReport: {
    pageTitleError: "Error loading details",
    pageTitle: "Stripe Payout Report",
    noReport: "No payout report has been set up for your organisation.",
  },
  communityCards: {
    sidebar: {
      title: "Community cards",
      filters: {
        draft: "Draft",
        published: "Published",
      },
      inputs: {
        state: "Status",
        portfolios: "Portfolios",
      },
    },
    addCommunityCard: "+ Add community card",
    noCommunityCards: "No community cards found",
    blankSlate: "Select or add a new community card",
    state: {
      published: "Published",
      draft: "Draft",
    },
  },
  communityCardsAdd: {
    title: "New community card",
    sections: {
      content: "Content",
      display: "Display",
      preview: "Preview",
    },
    fields: {
      title: "Title",
      description: "Description",
      color: "Background colour",
      url: "Link to further details",
      portfolios: "Portfolios",
    },
    hints: {
      title: "Max 50 characters",
      description: "Max 140 characters over 4 lines",
      url: "Optional",
      portfolios: "Where card will be visible in the app",
    },
    colors: {
      white: "White",
      green: "Green",
      orange: "Orange",
      purple: "Purple",
    },
  },
  communityCardsEdit: {
    cancel:
      "Are you sure you wish to cancel, this will reset any changed fields?",
    archive: {
      title: "Delete community card",
      description: "Are you sure you want to delete?",
    },
  },
  communityCardsValidations: {
    title: {
      required: "Title is required",
      length: "Title must be no more than 50 characters",
    },
    description: {
      length: "Description must be no more than 140 characters",
      lines: "Description must be no more than 4 lines",
    },
    url: {
      format: "Link must be a valid URL",
    },
    portfolios: {
      required: "At least 1 portfolio must be chosen",
    },
  },
  integrations: {
    blankSlate: "Select an integration",
    sidebar: {
      title: "Integrations",
    },
    noIntegrations: "No integrations found",
    types: {
      pms: "PMS",
      maintenance: "Maintenance",
    },
  },
  integrationShow: {
    instances: "Instances",
    description: "Description",
    configureInstance: "Configure new instance",
    noInstances: "No instances configured",
  },
  integrationInstanceConfigure: {
    description: "Description",
  },
  tokens: {
    crud: {
      emptyData: "-",
    },
  },
  insights: {
    introductionTitle: "Detailed Insights",
    introduction: [
      "Get a holistic view of your operations and make business decisions with confidence.",
      "Get rich demographic data of your customers to help you understand who your customers are.",
      "Get real-time comparable pricing and recommendations to maximise returns.",
      "To enable Insights, reach out to a member of the team.",
    ],
    poweredByAi: "Powered by ai",
  },
  components: {
    accountMenu: {
      accountMenuTitle: "Account",
      signOutItem: "Log out of Residently",
      moreOptionsTitle: "More options",
      termsAndConditionsItem: "Terms and Conditions",
      privacyPolicyItem: "Privacy Policy",
    },
    renewalTabs: {
      upcoming: "Upcoming",
      inReview: "In Review",
    },
    dateTime: {
      unknown: "Unknown",
      date: "dd MMM yyyy",
      dateFullMonth: "dd MMMM yyyy",
      dateTime: "dd MMM yyyy, HH:mm",
      dateTimeDotSeparator: "dd MMM • h:mm aaa",
      dateTimeNoDotSeparator: "dd MMM h:mm aaa",
      dayMonthYear: "dd/MM/yyyy",
      dayMonth: "EEE dd MMM",
      monthYear: "MMMM yyyy",
      timeAmPm: "h:mm aaa",
      dateTimeAmPm: "dd MMM yyyy h:mm aaa",
      timeSimple: "HH:mm",
    },
    joinStage: {
      done: "Done",
    },
    joinSubStage: {
      labels: {
        done: "Done",
        inProgress: "In progress",
        failed: "Failed",
      },
      completed: "Completed by:",
      toBeCompleted: "To be completed by:",
      amountDueDate: "Amount due by ",
      amountDue: "Amount due",
      dueBy: "Due by ",
    },
    certificateSubStage: {
      viewCertificateModal: "View",
      uploadCertificateModal: "Upload",
      editStatusModal: "Edit status",
      viewCertificate: "View certificate",
      certificateTypes: {
        epc: "Energy Performance Certificate (EPC)",
        gas_certificate: "Gas safety certificate",
        electrical_certificate: "Electric safety certificate",
      },
      filenameTitle: "File name",
      expiryTitle: "Certificate expiry date",
      confirmGas: "1. Does this property have gas?",
      confirmGasSubtitle:
        "If there is gas you will need to upload a gas safety certificate",
      file: {
        genericModal: "1. Upload a file",
        gasModal: "2. Upload a file",
      },
      fileSubtitle: "Only PDF, .jpg or .png files",
      expiryDate: {
        genericModal: "2. Enter the expiry date",
        gasModal: "3. Enter the expiry date",
      },
      sendEmail: {
        genericModal: "3. Notification options",
        gasModal: "4. Notification options",
      },
      sendCertificateEmail: "Send certificate to residents",
      certificateValidation: "Please upload a file",
      missingDateMessage: "Please provide an expiry date",
      retryUpload: "RETRY",
      error: {
        upload: "Certificate upload failed, please try again",
        submit: "Something went wrong, please try again",
      },
      deleteCertificate: "Are you sure you want to delete this certificate?",
    },
    contractsUploadSubStage: {
      uploadContractModalTitle: "Upload contract",
      uploadContractModal: "Upload",
      submitContractModal: "Submit",
      cancelContractModal: "Cancel",
      uploadFile: "Upload file",
      uploadFileDescription: "Only PDF files at 5mb or less",
      viewContractsModal: "View",
      viewContract: "View contract",
      contract: "Contract",
      document: "Document",
      contractsValidation: "Please upload a file",
      retryUpload: "RETRY",
      error: {
        upload: "Upload failed, please try again",
        submit: "Something went wrong, please try again",
      },
    },
    datePicker: {
      minDateMessage: "The expiry date must be in the future",
      invalidDateMessage: "Please use DD/MM/YYYY format",
      label: "DD/MM/YYYY",
      format: "dd/MM/yyyy",
    },
    timePicker: {
      invalidDateTimeMessage: "Please use DD/MM/YYYY HH:MM format",
      label: "DD/MM/YYYY HH:MM",
      format: "dd/MM/yyyy hh:mm a",
      hour: "Hour",
      minutes: "Minutes",
    },
    select: {
      label: "Select an option",
    },
    selectMultiple: {
      label: "Select one or more options",
    },
    fileDropZone: {
      certificates: {
        label: "Drag and drop files here or upload",
      },
      contracts: {
        label: "Drag and drop files here or upload",
      },
      properties: {
        label: "Upload image",
      },
    },
    collectHoldingDepositSubStage: {
      title: "View payment status",
      refresh: "Refresh",
      markAsCollectedModal: {
        buttonTriggerText: "Mark as collected",
        title: "Mark holding deposit as collected",
        description: {
          summary:
            "Marking the holding deposit as collected by partner will mean Residently will not take payment card details or attempt collection of the holding deposit from the resident.",
          warning: "This action cannot be undone.",
          question:
            "Are you sure you want to mark this holding deposit as collected?",
        },
        backButton: "Back",
        errors: {
          submitError: "Something went wrong, please try again",
        },
        success: "Holding deposit was successfully marked as collected.",
      },
      modalSubTitle: "Payment status",
      viewPaymentStatus: "View payment status",
      sendPaymentLink: "Send payment link",
      paymentLinkSent:
        "All done! An email with a payment link has been sent to the resident(s)",
      paymentLinkError:
        "There was an error sending the payment link, please try again",
      paymentStatus: {
        pending: "Pending",
        success: "Paid",
        failed: "Failed",
      },
    },
    offerSubStage: {
      title: "Review Offer",
      stayDetailsTitle: "Stay details",
      chargesTitle: "Rent & charges",
      upfrontChargesTitle: "Upfront payment details",
      householdTitle: "Household details",
      affordabilityText: "Affordability",
      modalTriggerTextDone: "View approved offer",
      modalTriggerTextInProgress: "Review",
      modalTriggerTextDecline: "View offer",
      total: "Total",
      startDate: "Move in date",
      termLength: "Contract length",
      breakOption: "Break option",
      adultCount: "Adults",
      childrenCount: "Children",
      pets: "Does anyone have pets?",
      smokers: "Is anyone a smoker?",
      yes: "Yes",
      no: "No",
      houseDetails: "Household details",
      householdIncome: "Annual household income",
      numberOfGuarantors: "Number of guarantors",
      guarantorIncome: "Guarantor income",
      preOfferConditions: "Pre-offer conditions",
      incomeSource: "Income source",
      contractType: "Contract type",
      annualSalary: "Annual salary",
      firstName: "First name",
      lastName: "Last name",
      userType: {
        lead_resident: "Resident",
        resident: "Resident",
        guarantor: "Guarantor",
      },
      email: "Email",
      mobile: "Phone number",
      dateOfBirth: "Date of birth",
      nationality: "Nationality",
      residentHasRightToRentDocumentsAvailable:
        "Can provide right to rent documents",
      jobTitle: "Job title",
      companyName: "Company name",
      jobStartDate: "Start date",
      onProbation: "On probation",
      adverseFinancials: "Adverse financials (CCJ, bankruptcies)",
      relationshipToResident: "Relationship to resident",
      guarantorFor: "Guarantor for",
      ukResident: "UK Resident",
      downloadOffer: "Download offer",
      requestNewOffer: "Request a new offer",
      requestNewOfferExplain: {
        one: "The resident will receive an email to submit a new offer",
        other: "The residents will receive an email to submit a new offer",
      },
      declineExplain: {
        one: "The resident will be notified that their offer was not accepted",
        other:
          "The residents will be notified that their offer was not accepted",
      },
      approveExplain: {
        one: "The resident will be notified to complete their paperwork",
        other: "The residents will be notified to complete their paperwork",
      },
      acceptExplain: {
        one: "The holding deposit will be taken and the resident will be notified to complete their paperwork",
        other:
          "The holding deposit will be taken and the residents will be notified to complete their paperwork",
      },
      error: "Something went wrong, please try again",
      declineReasons: {
        another_offer: "Another offer has been accepted",
        changed_mind: "Residents changed their mind",
        do_not_meet_affordability: "Residents do not meet affordability",
        duplicate_offer: "Duplicate offer",
        landlord_not_proceeding: "Landlord did not want to proceed",
        property_unavailable: "Property no longer available",
        other: "Other",
      },
      declineStay: {
        stayDeclined: "Successfully declined the stay",
        title: "Decline stay",
        decline: "Decline stay",
        declineReasonTitle: "Reason for declining",
        declineReasonLabel: "Select a reason",
        declineReasonError:
          "You need to select a reason for declining the offer",
        declineExternalReasonTitle: "Message to the resident",
        declineExternalReasonHelpText:
          "This will be sent to the resident via email",
        declineExternalReasonLabel: "Start typing a message to the resident",
        declineExternalReasonError:
          "You need to write a message to the resident before you can decline the offer",
        refundHoldingDepositTitle: "Holding deposit",
        refundHoldingDepositLabel: "Refund the holding deposit to the resident",
      },
      securityDeposit: "Security deposit",
      holdingDeposit: "Holding deposit",
      upfrontRentAmount: "Upfront rent amount",
      upfrontRentMonths: "Upfront rent months",
      resendInviteModal: {
        title: "Resend offer invite",
        description:
          "By resending this the residents will receive another email with the link to the offer. ",
        resendButton: "Resend offer invite",
        retry: "RETRY",
        performFailed: "Sending invite failed. Please refresh and try again.",
        performSucceeded: "Your offer has been sent to the resident.",
      },
    },
    paymentStageData: {
      titleInProgress: "Confirm upfront payment received",
      titleDone: "Upfront payment received",
      modalTriggerTextDone: "View",
      modalTriggerTextInProgress: "Confirm",
      initialPayment: "Total",
      submit: "Confirm",
      undo: "Undo",
    },
    upfrontPaymentRequestData: {
      titleInProgress: "Request upfront payment",
      accountDetails: "Account details",
      paymentAmounts: "Payment amounts",
      titleDone: "Upfront payment requested",
      modalTriggerTextInProgress: "Request payment",
      modalTriggerTextDone: "View",
      submit: "Request",
      resubmit: "Resend",
      modalLabels: {
        accountName: "Account name",
        accountNumber: "Account number",
        accountIban: "IBAN",
        reference: "Payment reference",
        sortCode: "Sort code",
        swiftCode: "SWIFT code/BIC",
      },
      errors: {
        submitError: "Something went wrong, please try again",
        noAccountData:
          "Bank information incomplete. Chat to our team to update.",
        noPayments:
          "Payment information incomplete. Chat to our team to update.",
      },
      success: "The request for upfront payment has been sent",
    },
    paymentReferenceStageData: {
      titleInProgress: "Submit payment reference",
      titleDone: "Payment reference",
      modalTriggerTextDone: "View",
      modalTriggerTextInProgress: "Submit",
      labelText: "Payment reference",
      errorText: "Please enter a payment reference",
    },
    referencingStage: {
      title: "Reference report",
      viewReferencingProgress: "Review",
      viewApprovedReferencingModal: "View approved report",
      interimAlert:
        "You can approve the referencing based on the interim reports available, or you can wait for the final full report.",
      helpText:
        "If you would like to make changes to the application based on this reference report please use chat or drop us an email at",
      referenceApproved:
        "The referencing for this application has been approved",
      undoApprove: "Undo approval",
      confirmUndo1:
        "The residents have been notified that referencing is approved.",
      confirmUndo2: "Please keep them informed of the changes.",
      let_alliance: {
        credentials: {
          description: {
            prefix: "These are your login details for your ",
            dashboard: {
              text: "Let Alliance dashboard",
              link: "https://vision.letalliance.co.uk/public/home.aspx",
            },
          },
          partnerCredentials: {
            agentCode: "Agent account number: ",
            partnerUsername: "Username: ",
          },
        },
        contactDetails: {
          prefix:
            "If you have any questions about an ongoing or completed application contact Let Alliance by ",
          email: {
            text: "email",
            link: "mailto:cities@letalliance.co.uk?subject=Enquiry%20ID%3A%20%{enquiryId}",
          },
          quote:
            "You will need to quote your enquiry ID and Residently as the account when calling or emailing.",
        },
      },
      let_alliance_vision_plus: {
        credentials: {
          description: {
            prefix: "These are your login details for your ",
            dashboard: {
              text: "Let Alliance dashboard",
              link: "https://agent.letalliance.co.uk",
            },
          },
          partnerCredentials: {
            agentCode: "Agent Code: ",
            partnerUsername: "Username: ",
          },
        },
        contactDetails: {
          prefix:
            "If you have any questions about an ongoing or completed application contact Let Alliance by ",
          email: {
            text: "email",
            link: "mailto:cities@letalliance.co.uk?subject=Applicant%20ID%3A%20%{providerId}",
          },
          quote:
            "You will need to quote your Applicant ID and Residently as the account when calling or emailing.",
        },
      },
    },
    referencingProgressStage: {
      title: "Referencing progress for %{name}",
      viewProgress: "View progress",
      additionalInformation: "Additional information",
      status: {
        done: "Done",
        inProgress: "In progress",
        notApplicable: "Not applicable",
      },
      status_insight: {
        title: "Progress insight",
        tooltip:
          "This insight is generated by our AI from activity on this referencing request",
      },
      reports: {
        title: "Reports",
        types: {
          interim: "Interim report",
          final: "Final report",
        },
        lastRefreshed: "Last updated %{date}",
        noUpdate: "Updated report not showing?",
        refresh: "Refresh report",
      },
      tasks: {
        title: "%{done}/%{total} checks done",
      },
      rightToRent: {
        title: "Right to rent",
      },
      comments: {
        title: "Let Alliance comments",
      },
    },
    contractStage: {
      viewContractModal: "Review",
      viewApprovedContractModal: {
        one: "View approved contract",
        other: "View approved contracts",
      },
      approveContract: {
        one: "Approve contract",
        other: "Approve contracts",
      },
      helpText:
        "If you would like to make changes to this contract please use chat or drop us an email at ",
      contractApproved: {
        one: "The contract for this application has been approved. Please make sure you have uploaded all property certificates.",
        other:
          "The contracts for this application have been approved. Please make sure you have uploaded all property certificates.",
      },
    },
    contractIssueStage: {
      viewContractModal: "Review",
      viewIssuedContractModal: {
        one: "View issued contract",
        other: "View issued contracts",
      },
      contract: "Contract",
      issueContract: {
        one: "Issue contract",
        other: "Issue contracts",
      },
      helpText:
        "If you would like to make changes to this contract please use chat or drop us an email at ",
      contractIssued: {
        one: "The contract for this application has been issued.",
        other: "The contracts for this application have been issued.",
      },
      archiveContract:
        "Archiving a contract will allow you to issue a new version and it will no longer be possible to sign the original document.",
      contractUnarchivable:
        "It is not possible to archive this contract because everyone has signed. Please use chat or drop us an email at partners@residently.com if you need help.",
      contractArchivedNotDeleted:
        "The contract for this application has been archived but could not be deleted. Please try again.",
      archiveError: "This contract could not be archived, please try again.",
    },
    contractSignatoriesSubStage: {
      viewSignaturesModal: "View signatures",
      modalTitle: "Contract signatures",
      modalSubTitle: "People",
      closeModal: "Done",
      sendReminder: "Send reminder",
      reminderSent: "Reminder sent",
      sentLabel: "Sent",
      signedLabel: "Signed",
      peopleType: {
        residents: "Residents",
        guarantors: "Guarantors",
        counterSigners: "Counter Signatories",
      },
    },
    contractSetupSubStage: {
      setup: "Setup",
    },
    generateIssueContractSubStage: {
      generateAndIssue: "Generate and issue",
    },
    createContractSubStage: {
      complianceDocumentsTitle: "Compliance documents",
      complianceDocumentsContent:
        "Compliance documents are sent to the resident/s as email attachments alongside the contract. Tick the box below to also insert them into the contract being sent for signature.",
      attachDocuments: {
        tooltip:
          "All compliance documents must be uploaded before they can be inserted into the contract",
      },
      sendWithoutOptionalDocuments: {
        label: "Generate and issue the contract without the missing document/s",
        error:
          "Please confirm that you want to proceed without the missing document/s",
      },
      clause: {
        title: "Add additional clause",
        optional: "Optional",
        subTitle:
          "Add text to insert a clause in the contract. Leave blank if no additional clause is needed.",
        inputPlaceholder: "Start typing your additional clause",
      },
    },
    viewContractStaySummarySubStage: {
      viewStaySummaryModal: "Review",
      title: "Stay summary",
      na: "Not available",
      confirm: "Confirm",
      confirmation: "This information looks correct",
      confirmationError: "You must confirm you've reviewed the stay summary",
      month: {
        one: "month",
        other: "months",
      },
      stayDetails: {
        title: "Stay details",
        startDate: "Start date",
        endDate: "End date",
        breakDate: "Break date",
        leaseLength: "Lease length",
        leaseBreak: "Lease break",
        furnishing: "Furnishing",
        rentPerMonth: "Rent per month",
        rentDate: "Rent payment date",
        advanceRent: "Upfront rent",
        advanceRentPayment: "Payment due date",
        securityDeposit: "Security deposit",
        subscription: "Subscription",
        residentlyMove: "Residently Move",
        values: {
          furnished: "Furnished",
          unfurnished: "Unfurnished",
          rentDate: "%{day} of month",
          depositScheme: {
            dps: "DPS",
            tds: "TDS",
            mydeposits: "Mydeposits",
          },
        },
      },
      peopleDetails: {
        title: "People",
        headers: {
          residents: "Residents",
          guarantors: "Guarantors",
          occupiers: "Lawful occupiers",
        },
      },
      landlordDetails: {
        title: "Landlord/signatory",
        name: "Name",
        address: "Address",
        signatory: "Signatory",
      },
      accountDetails: {
        title: "Bank details for upfront payment",
        subsequentTitle: "Bank details for ongoing rent payments",
        accountName: "Account name",
        reference: "Payment reference",
        accountNumber: "Account number",
        sortCode: "Sort  code",
        iban: "IBAN",
        swiftCode: "SWIFT code/BIC",
      },
    },
    referencingRequests: {
      interimReport: "Interim report",
      finalReport: "Final report",
      enquiryId: "Enquiry ID: %{enquiryId}",
      providerId: "Applicant ID: %{providerId}",
      residents: {
        one: "Resident report",
        other: "Residents reports",
      },
      guarantors: {
        one: "Guarantor report",
        other: "Guarantor reports",
      },
      details: "Reference details",
      recommendations: {
        proceed: "Proceed",
        proceedWithCaution: "Proceed with caution",
        caution: "Caution",
      },
      riskScores: {
        low: "Low risk",
        mediumLow: "Medium-low risk",
        medium: "Medium risk",
        high: "High risk",
        acceptable: "Acceptable",
        not_acceptable: "Not Acceptable",
        guarantor_required: "Guarantor Required",
        incomplete: "Incomplete",
      },
      progress: {
        inProgress: "In progress",
        done: "Done",
      },
    },
    joinInfoContainer: {
      startDateTitle: "Stay start date",
      endDateTitle: "Stay end date",
      daysTilStartTitle: "Days to start date",
      rollingEndDate: "Rolling",
      daysRemaining: {
        zero: "Today",
        one: "%{count} day",
        other: "%{count} days",
      },
      residentsTitle: "Residents",
      emailResidents: "Email all residents",
      guarantorsTitle: "Guarantors",
      userEmailTitle: "Email",
      userMobileTitle: "Mobile",
      preOfferConditionsTitle: "Pre-offer conditions",
      preOfferNoConditions: "No conditions",
      preOfferViewConditions: "View conditions",
      completed: "All steps completed",
      propertyServiceType: {
        managed: "",
        let_only: "Let only",
        rent_collection: "Rent collection",
      },
    },
    zendeskChat: {
      greeting:
        "Hi, we're here to answer ongoing application queries. Please add the property into your message to help us respond quicker.\n\n For help on managing an application visit our help centre at rdly.to/helpcentre\n\n For general account enquires you can email us at partners@residently.com",
    },
    fileDownload: {
      download: "Download",
      cta: "Need to customise the contract for this stay?",
      uploaded: "Download and edit a template here",
      downloadPrefilled: "Download prefilled contract",
      downloading: "Downloading",
      done: "Done",
      save: "Save",
      intro:
        "Most contracts can be generated from a template. Occasionally you may need to customise a contract, e.g. to edit wording of a clause. Here you can download a docx version of the contract to edit in Word and upload for signing.",
      tagWarning: {
        example:
          "Document will contain signature tags which show where people sign - they look like this: [sig|req|signer1]",
        warning:
          "Be careful not to change them during editing as they are required to issue the contract.",
      },
      tooltip: "Example of signature tags",
      upload: "Upload file",
      uploadFiletype: "File must be in doc or docx format",
      uploadEditedContract: "Upload edited contract",
    },
    uploadContractControls: {
      label: "Need to make changes to this document?",
      tooltip: {
        title: "Making changes to a contract",
        data: {
          title: "Data",
          description:
            "If incorrect details for the lease term or people have been inserted into the contract e.g.move in date or monthly rent, first correct them on the system by editing the stay, then delete and regenerate the contract.",
        },
        wording: {
          title: "Wording",
          description:
            " If you need to make further changes to the wording of the contract itself, you can now download a docx version to edit and upload.",
        },
      },
      editReupload: "Edit and re-upload",
      deleteModal:
        "You need to delete the current contract to download and edit.",
    },
    fileUploadItem: {
      cannotPreview: "Cannot show preview",
      confirm: "Confirm",
    },
    propertyListingFileUpload: {
      addFile: "Add file",
      fileTypes: "PDF, JPEG, JPG, PNG",
      uploadFile: "Upload file",
    },
    documents: {
      downloadAll: "Download all",
      downloadAllAlert: "Your downloads have started.",
      close: "Close",
      modalButtonText: "Documents",
      modalTitle: "Documents",
      viewText: "View",
      residentDocumentsTitle: "Paperwork - %{name}",
      guarantorDocumentsTitle: "Paperwork - %{name} (Guarantor)",
      noContracts: "Contracts will be available once all parties have signed.",
      contractsError:
        "Contracts are temporarily unavailable, please try again later",
      noDocuments: "Documents available on paperwork submission",
      documentsError:
        "Documents are temporarily unavailable, please try again later",
      stayContracts: "Contracts",
      otherDocuments: {
        certificates: {
          one: "Certificate",
          other: "Certificates",
        },
        contracts: {
          one: "Contract",
          other: "Contracts",
        },
        references: {
          one: "Reference report",
          other: "Reference reports",
        },
      },
      documentTypes: {
        boarding_pass: "Flight ticket or boarding pass (EU national only)",
        contract: "Employment Contract",
        dividends_bank_statement: "Bank Statement (Dividends)",
        dividends_shares_confirmation: "Shares Confirmation (Dividends)",
        electrical_certificate: "Electrical Certificate",
        employed_bank_statement: "Bank Statement (Employed)",
        epc: "EPC",
        freelance_bank_statement: "Bank Statement (Freelance)",
        freelance_tax_return: "Tax Return",
        gas_certificate: "Gas Certificate",
        immigration_document: "Immigration Document",
        other_bank_statement: "Bank Statement (Other)",
        other_income_proof: "Misc. Proof of Income",
        other_right_to_rent: "Misc. Right to rent Document",
        passport: "Passport",
        passport_page: "Passport page",
        payslip: "Payslip",
        proof_of_address: "Proof of Address",
        rental_income_bank_statement: "Bank Statement (Rental Income)",
        rental_income_lease: "Copy of Lease Agreement",
        rental_income_ownership_proof: "Proof of Ownership of Property",
        residence_permit: "Residence Permit",
        self_employed_bank_statement: "Bank Statement (Self-Employed)",
        self_employed_tax_return: "Tax Return",
        self_employed_w2_or_1099_forms: "Proof of Income",
        stay_contract: "Contract - Issued %{date}",
        student_bank_statement: "Bank Statement (Student)",
        study_confirmation: "Confirmation of Study",
        trust_bank_statement: "Bank Statement (Trust)",
        trust_income_statement: "Trust Income Statement",
        unemployed_bank_statement: "Bank Statement (Unemployed)",
        uk_pension_annual_statement: "Annual Pension Statement",
        uk_pension_bank_statement: "Bank Statement (UK Pension)",
        usa_drivers_license: "Drivers License",
        w2_form: "W-2 Form",
      },
    },
    actions: {
      buttonText: "Actions",
      reopenPaperwork: {
        title: "Reopen paperwork",
        modalText: {
          paperworkOpen: {
            one: "Paperwork is open and needs to be submitted by the resident.",
            other:
              "Paperwork is open and needs to be submitted by the residents.",
          },
          lastReopened: "Last reopened",
          cannotReopen:
            "Paperwork cannot be reopened because the contract has been issued. If you have any questions please use chat or drop us an email at ",
          reopen: {
            whenInfo: {
              title: "When to use Reopen paperwork",
              explain: "You can use reopen paperwork if a resident needs to:",
              addPeople: "add more people",
              addServices: "add or remove services",
            },
            alreadySubmitted:
              "If a resident needs to make a change to an already submitted person, please use chat or drop us an email at ",
            afterInfo: {
              title: "After reopening paperwork",
              notification: {
                one: "The resident will receive an email notification to let them know paperwork has been reopened.",
                other:
                  "The residents will receive an email notification to let them know paperwork has been reopened.",
              },
              explain: "They can access paperwork using:",
              link: "the link in the email notification",
              websitePre: "logging in on the ",
              website: "Residently website",
            },
            mustResubmit: {
              one: "Once changes have been made, the resident must resubmit paperwork.",
              other:
                "Once changes have been made, the residents must resubmit paperwork.",
            },
          },
        },
        reopenError: "There was an error reopening paperwork, please try again",
        reopenSuccess: "Paperwork is now reopened",
      },
    },
    propertyList: {
      address: "Address",
      status: "Status",
      furnishing: "Furnishing",
      bedrooms: "Bedrooms",
      emptyMessage: "Start adding your first property",
    },
    propertyListItem: {
      status: {
        void: "Void",
        let: "Let",
      },
      furnished: "Furnished",
      unfurnished: "Unfurnished",
    },
    navbar: {
      find: "Find",
      applications: "Applications",
      stays: "Stays",
      renewals: "Renewals",
      properties: "Properties",
      help: "Help Centre",
      listings: "Listings",
      leads: "Leads",
      lets: "Properties to let",
      search: {
        placeholder: "Search properties",
        noResultsText: "No results",
      },
      viewings: "Viewings",
      viewingAvailability: "Viewing Availability",
      payments: "Payments",
      insights: "Insights",
      previewLabel: "Preview",
      community: "Community",
    },
    navigation: {
      error:
        "Sorry, we could not complete that action. Please try again, or let us know if the problem persists",
    },
    formNavButton: {
      finish: "Finish",
      addFinish: "Finish",
      editFinish: "Save changes",
      next: "Next",
      back: "Back",
      cancel: "Cancel",
    },
    addressInputs: {
      propertyAddress: "Property address",
      manualAddress: "Or enter address manually",
      subpremise: "Flat number (Optional)",
      houseNumber: "House number",
      houseName: "House name",
      streetLine1: "Street line 1",
      streetLine2: "Street line 2",
      city: "City",
      postcode: "Postcode",
      region: "Region",
    },
    addressSearch: {
      searchInputs: {
        searchLabel: "Postcode / Address",
        searchPrompt: "Search for postcode or address",
      },
    },
    addProperty: {
      propertyDetails: {
        furnished: "Is the property furnished?",
        furnishedStatus: {
          furnished: "Furnished",
          unfurnished: "Unfurnished",
        },
        bedrooms: "Bedroom Count",
        portfolio: "Which portfolio is this property in?",
        serviceType: "What is the property service type?",
        serviceTypes: {
          managed: "Managed",
          let_only: "Let only",
          rent_collection: "Rent collection",
        },
        reservationPossible: "Display property on agent form?",
        reservationPossibleTooltip:
          "This field determines whether a property appears on the agent form. If yes the property is searchable and available to start a new stay",
      },
      landlordForm: {
        labels: {
          nameLabel: "Landlord name",
          nameTooltip:
            "This is the full legal name of the landlord that will be used in the contract",
          name: "Full name / Company",
          address: "Landlord address",
          signatoryLabel: "Contract signatory details",
          signatoryTooltip:
            "This is the person who will countersign the contract. This can be the landlord or another person with power of attorney",
          email: "Email address",
        },
        errors: {
          name: "Name required",
          email: {
            missing: "Email address required",
            invalid: "Please enter a valid email address",
          },
        },
      },
    },
    propertyDetails: {
      title: "Property details",
      address: "Address",
      bedrooms: "Bedrooms",
      furnishing: "Furnishing",
      furnished: "Furnished",
      unfurnished: "Unfurnished",
      propertyId: "Property ID",
      portfolio: "Property portfolio",
      service: "Service Type",
      serviceType: {
        let_only: "Let only",
        managed: "Managed",
        rent_collection: "Rent collection",
      },
      reservationPossible: "Display on agent form?",
    },
    propertySignatoryDetails: {
      title: {
        one: "Landlord details",
        other: "Second landlord details",
      },
    },
    signatoryItem: {
      name: "Landlord name",
      address: "Landlord address",
      signatory: "Contract signatory",
      email: "Signatory email address",
    },
    propertyCertificates: {
      title: "Certificates",
    },
    propertyCertificateCard: {
      expires: "Expires: ",
      certificateTypes: {
        epc: "Energy Performance Certificate (EPC)",
        gas_certificate: "Gas safety certificate",
        electrical_certificate: "Electric safety certificate",
      },
      deleteModal: {
        description: "Are you sure you want to delete this certificate?",
      },
      deleteAlert: "%{certificate} has been removed",
    },
    propertyCertificatesModalTrigger: {
      epc: "Upload energy performance certificate (EPC)",
      gas_certificate: "Confirm gas status (upload certificate)",
      electrical_certificate: "Upload electric safety certificate",
    },
    showPropertyTabs: {
      labels: {
        property: "Property",
        stays: "Stays",
        listings: "Listings",
      },
    },
    viewContracts: {
      backToTracker: "Back to join tracker",
      contract: "Contract",
      issued: "Issued",
    },
    radioButtonGroup: {
      errors: {
        selectOption: "Please select an option",
      },
    },
    propertySupportDetails: {
      title: "Support details",
      name: "Contact name",
      emergencyContactNumber: "Telephone number",
      supportEmail: "Email address",
    },
    basicDetails: {
      title: "Basics",
      phone: "Phone number",
      personalEmail: "Personal email address",
      residentlyEmail: "Residently email address",
      contactAddress: "Contact address",
      nextOfKin: {
        name: "Next of kin name",
        telephone: "Next of kin telephone",
        address: "Next of kin address",
      },
    },
    guarantorBasicDetails: {
      guarantorFor: "Guarantor for",
    },
    incomeDetails: {
      title: "Primary income",
      titleOther: "Other income",
      proof: {
        type: {
          payslip: "Payslips",
          bank_statement: "Bank statements",
          other: "Other",
        },
      },
      type: {
        label: "Type",
        options: {
          employed: "Employed",
          unemployed: "Unemployed",
          "self-employed": "Self-employed",
          student: "Student",
          other: "Other",
          trust: "Trust",
          freelance: "Freelance",
          uk_pension: "UK pension",
          dividends: "Dividends",
          rental_income: "Rental income",
          none: "None",
        },
      },
      student: {
        institution: "Place of study",
        course: "Field of study",
        start: "Start date",
        end: "End date",
        confirmation: "Study confirmation",
      },
      employed: {
        grossIncome: "Gross annual income",
        netMonthlyIncome: "Net monthly income",
        contractType: {
          label: "Contract type",
          options: {
            permanent: "Permanent",
            fixed: "Fixed",
            temporary: "Temporary",
          },
        },
        startDate: "Start date",
        employer: "Employer",
        paymentFrequency: {
          label: "Payment frequency",
          options: {
            weekly: "Weekly",
            monthly: "Monthly",
            other: "Other",
          },
        },
        jobTitle: "Job title",
        description: "Additional income info",
        referee: "Referee",
        refPosition: {
          label: "Referee position",
          options: {
            hr: "HR",
            manager: "Manager",
            director: "Director",
          },
        },
        refEmail: "Referee email",
        refEmailDescription: "Referee email description",
        refPhone: "Referee phone",
        financialIssues: {
          label: "Financial issues",
          options: {
            true: "CCJs declared",
            false: "No CCJs declared",
          },
        },
      },
    },
    contactDetails: {
      contactAddressOptional: "Contact address (optional)",
      tooltip: {
        title: "Contact address",
        line1:
          "This address will be used to populate the current address in any contracts. In most cases, residents and guarantors will add this when completing paperwork.",
        line2:
          "You can add or edit this for an existing resident or guarantor where needed.",
      },
    },
    personalDetails: {
      title: "Identity",
      dateOfBirth: "Date of birth",
      nationality: "Nationality",
      occupierType: "Type",
      identityProof: "Proof of identity",
    },
    rightToRentDetails: {
      title: "Right to rent",
      checkShareCode: "Check",
      rightToRentShareCode: "Share code",
      rightToRentUniqueApplicationNumber: "Unique application number",
      rightToRentError:
        "We have not been able to verify the right to rent status of this resident.",
      rightToRentStatus: "Status",
      rightToRentStatuses: {
        notChecked: "Not checked",
        failed: "Not verified",
        not_yet_valid: "Not yet valid",
        expired: "Expired",
        valid: "Valid",
      },
      rightToRentExpiry: "Expiry",
      rightToRentNoExpiryLimit: "No limit",
      rightToRentValidFrom: "Valid from",
      rightToRentReference: "Reference",
      rightToRentChecked:
        "We have checked the right to rent status of this resident on %{checked}",
      rtrProof: {
        one: "Right to rent document",
        other: "Right to rent documents",
      },
    },
    addressHistoryDetails: {
      title: "Address history",
      proofOfAddress: {
        label: "Proof of address",
        view: "View",
        download: "Download",
      },
      landlord: {
        name: "Landlord name",
        email: "Landlord email",
        phone: "Landlord phone",
        address: "Landlord address",
      },
    },
    rentShareDetails: {
      title: "Share of rent",
      rentShare: "Rent share",
    },
    reinstateStayAlert: {
      message: `This stay was declined on %{date} due to '%{reason}'. Declined stays cannot progress further.`,
      buttonText: "Reinstate stay",
      modalMessage: "Are you sure you want to reinstate this stay?",
      successAlert: "This stay has been successfully re-instated",
      successAlertAtPaperwork:
        "This stay has been successfully reinstated, we've asked the resident to complete their paperwork",
    },
    reinstateUserAlert: {
      message: `This user was archived from the stay as of %{date}`,
      buttonText: "Reinstate %{user_type}",
      modalMessage: "Are you sure you want to reinstate this user?",
      successAlert: "This user has been successfully re-instated",
    },
    listingsList: {
      header: {
        status: "Status",
        full_address: "Address",
        last_published: "Last published at",
        price: "Price",
        viewingAvailability: "Viewing availability",
      },
    },
    listingState: {
      stateLabel: {
        draft: "Draft",
        publishing: "Publishing",
        published: "Published",
        removing: "Removing",
      },
    },
    listingPortalState: {
      stateLabel: {
        inactive: "Inactive",
        active: "Active",
        publishing: "Publishing",
        removing: "Removing",
      },
    },
    listingRow: {
      fields: {
        time: "Time",
        address: "Address",
        price: "Price",
        email: "Email",
        number: "Number",
      },
    },
    viewingsTabs: {
      upcoming: "Upcoming",
      past: "Past",
    },
    viewingsList: {
      header: {
        status: "Status",
        time: "Time",
        resident: "Resident",
        address: "Address",
      },
      event: {
        viewingsCount: {
          one: "%{count} viewing",
          other: "%{count} viewings",
        },
      },
    },
    viewingRow: {
      fields: {
        time: "Time",
        address: "Address",
        name: "Name",
        email: "Email",
        number: "Number",
      },
    },
    viewingState: {
      state: {
        viewingEvent: "Viewing event",
        new: "Booked",
        cancelled: "Cancelled",
        started: "Started",
        no_show: "Viewing no show",
        happened: "Happened",
        requested: "Requested",
        booked: "Booked",
      },
    },
    viewingAvailabilityStatus: {
      state: {
        unavailable: "Unavailable",
        available: "Available",
        paused: "Paused",
      },
    },
    lookupInput: {
      noResults: "No results",
      labels: {
        property: "Filter by property",
        propertyActiveListing: "Select a property with an active listing",
        propertyTooltip:
          "Enquiries can only be added for properties with an active listing",
        user: "Filter by resident",
      },
    },
    propertyListingState: {
      state: {
        draft: "Draft",
      },
    },
    gallery: {
      defaultImgAlt: "Image of property #%{id}",
      noCaption: "No caption",
      noImagesUploaded: "No images uploaded",
      noImages: "Please upload at least one image",
      primaryPhoto: "Primary image",
    },
    listingMedia: {
      addMedia: "Add images",
      fileExtensions: "JPEG, JPG, PNG",
      dropZoneLabel: "Upload images",
    },
    tenancyReferenceTriggerWithModal: {
      titleInProgress: "Submit tenancy reference",
      labelText: "Tenancy reference",
      errorText: "Please enter a tenancy reference",
    },
    tenancyReferenceStageData: {
      titleDone: "Tenancy reference",
      labelText: "Tenancy reference",
      modalTriggerTextDone: "View",
    },
    joinActions: {
      addResident: {
        label: "Add a resident",
        firebase: "join_actions_menu_add_resident",
      },
      addGuarantor: {
        label: "Add a guarantor",
        firebase: "join_actions_menu_add_guarantor",
      },
      amendStartDate: {
        label: "Amend start date",
        firebase: "join_actions_menu_amend_start_date",
      },
      amendRentSecurityDeposit: {
        label: "Amend rent / security deposit",
        firebase: "join_actions_menu_amend_rent_security_deposit",
      },
      declineApplication: "Decline application",
      reassignLeadResident: {
        label: "Reassign lead resident",
        firebase: "join_actions_menu_reassign_lead_resident",
      },
      reassignProperty: {
        label: "Reassign property",
      },
    },
    paperworkProgress: {
      basics: "Basics",
      identity: "Identity",
      addressHistory: "Address history",
      income: "Income",
      submitted: "Submitted",
      notice: {
        lead: "%{name} is responsible for completing paperwork for everyone",
        individual: "People are responsible for completing their own paperwork",
      },
    },
    paperworkProgressTriggerWithModal: {
      title: "Paperwork progress",
      button: "View progress",
      footer: "Paperwork submitted",
    },
    paperworkProgressRow: {
      inviteNotAccepted: "Invite not accepted • Sent %{inviteSentAt}",
      inviteNotSent: "Invite not sent",
      inviteBtn: {
        unsent: "Send invite",
        sent: "Resend invite",
      },
    },
    viewingAvailabilityTabs: {
      daily: "Daily overrides",
      weekly: "Weekly schedule",
      events: "Events",
      settings: "Settings",
    },
    listHeader: {
      resultCount: {
        one: "1 result found",
        other: "%{count} results found",
        zero: "No results found",
      },
    },
    notesModal: {
      title: "Notes",
      buttonText: "Notes",
      loadingError: "Error loading notes",
    },
    previewAlert: {
      message:
        "This is a preview of our new %{area} interface, some information may be missing or incomplete.",
    },
    integrations: {
      information: {
        title: "Information",
        fields: {
          type: "Type",
          identifier: "Identifier",
          validAssociations: "Valid associations",
        },
      },
      configuration: {
        title: "Configuration",
        noConfiguration: "No configuration",
      },
      instanceConfigurationOptions: {
        title: "Instance configuration options",
        noOptions: "No instance configuration options",
        fields: {
          label: "Label",
          name: "Name",
          type: "Type",
        },
      },
      instance: {
        associations: "Associations",
        noAssociations: "No associations",
        configureAssociation: "Configure new association",
      },
      instanceConfiguration: {
        title: "Configuration",
        noConfiguration: "No instance configuration",
      },
    },
    chargesList: {
      includeInRef: "Include in amount to reference?",
      addCharge: "+ Add additional charge",
      descriptionPlaceholder: "Select",
    },
  },
};

export default en;
